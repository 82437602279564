import {
  Component,
  Output,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input  
} from '@angular/core';
import {
  PostStatus,
  ListPostOptions,
} from '@eview/core/domain/post/post';
import { AppState } from '@eview/core/store/states/app.state';
import { Store } from '@ngrx/store';
import { environment } from '@eview/core/environments/environment';
import { Form } from '@eview/core/domain/form/form';
import { selectForms } from '@eview/core/store/selectors/forms.selector';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ListFormAttributes } from '@eview/core/store/actions/form-attributes.actions';
@Component({
  selector: 'eview-forms',
  templateUrl: 'forms.component.html',
  styleUrls: ['forms.component.scss']
})
export class FormsComponent implements OnInit, OnDestroy {

  private subs: Subscription;
  public userCanAccessImportExport: boolean;
  formList: Form[] = [];
  selectedFormId: number;
  @Input() formId: number;
  @Input() type: number;
  @Output() onFormSelect: EventEmitter<Number> = new EventEmitter();
  currentLang: string = this.translate.currentLang;
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private translate: TranslateService
    ) {
    this.subs = new Subscription();
  }

  ngOnInit() {
    this.selectedFormId = (this.formId)? this.formId : environment.form.id;
     this.subs.add(
        this.store
        .select(selectForms)
        .subscribe(
          forms => {
            if (forms) {
              forms.results.forEach((form, index) => {
                if(this.currentLang === 'am') {
                  forms.results[index]['form_name'] = form['name_am'];
                } else {
                  forms.results[index]['form_name'] = form.name;
                }
                
              });
              this.formList = forms.results.filter(form => !form.disabled);
            }
          }
      )
    );
    this.subs.add(
      this.translate.onLangChange.subscribe((langChangeEvent:LangChangeEvent) =>{
        this.formList.forEach((form, index) => {
          if(langChangeEvent.lang === 'am') {
            this.formList[index]['form_name'] = form['name_am'];
          } else {
            this.formList[index]['form_name'] = form.name;
          }
        });
      })  
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onFormChange() {
    setTimeout(() => {
      this.formId = this.selectedFormId;
      this.onFormSelect.emit(this.formId);
    }, 500);
  }

  getFormType(form) {
    if (!this.type) {
      return true;
    }
    return form.type === this.type;
  }
}