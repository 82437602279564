import { Component,
		 OnDestroy,
		 OnInit,
		 Input, Output, EventEmitter } from '@angular/core';
import { Store 							} from '@ngrx/store';
import { Roles 							} from '@eview/core/models/roles';
import { selectRoles 					} from '@eview/core/store/selectors/roles.selector';
import { AppState 						} from '@eview/core/store/states/app.state';
import { Permission 					} from '@eview/core/auth/permission';
import { FormGroup,
		 FormBuilder,
		 Validators						} from '@angular/forms';
import { AuthHelpers 					} from '@eview/core/auth';
import { AllowedPriviledge 				} from '@eview/core/auth/auth';
import { UsersHelpers 					} from '@eview/core/users/users.helpers';
import { filter } from 'rxjs/operators';
import { Observable, Subscription, of, throwError	} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '@eview/core/users/user.service';
import { selectMap } from '@eview/core/store/selectors/map.selector';
import { ContactType } from '@eview/core/models/user';
import { PostSource } from '@eview/core/domain/post/post';
import { ToastType } from '../../toast/toast.component';
import { ToastService } from '../../toast/toast.service';
import { isEmpty } from 'lodash';
@Component({
  selector		: 'eview-user-signup',
  templateUrl	: 'user-signup.component.html',
  styleUrls		: ['user-signup.component.scss']
})

export class UserSignupComponent implements OnInit, OnDestroy {

  @Input() isEditing      : boolean;
  @Input() userData       : any;
  @Input() editId         : any;
  @Output() onModalClose  : EventEmitter<Boolean> = new EventEmitter();
  // @Output() onCancelClick: EventEmitter<Boolean> = new EventEmitter();
  @Input() isAnonymous    : boolean;
  @Input() isApproved     : boolean;
  @Input() changePassword : boolean = false;

	AuthHelpers 		  = AuthHelpers;
	AllowedPriviledge = AllowedPriviledge;
	Permission 			  = Permission;
	UsersHelpers 		  = UsersHelpers;
  
  subs				  : Subscription;
  userForm			: FormGroup;
  regions				: any[];
  roles$				: Observable<Roles> = this.store.select(selectRoles).pipe(filter(roles => roles !== null));

  constructor(
    private store			  : Store<AppState>,
		private userService : UserService,
		private formBuilder	: FormBuilder,
		private toast       : ToastService
  ) {
    this.subs 			= new Subscription();
  }

  ngOnInit() {
    if (this.changePassword){ this.createChangePasswordForm();  } 
    else                    { this.createUserForm();        
                              this.getRegions();
    }
    this.onEditClick();
  }

  ngOnDestroy() {
    this.userForm.reset();
    this.subs.unsubscribe();
  }

  createUserForm() {
    let items = {
      realname	    	: ['', Validators.required],
      email		      	: ['', [Validators.required, Validators.email]],
      role		      	: ['', (this.isAnonymous) ? Validators.nullValidator : Validators.required],
      mgmt_lev_1			: ['', Validators.nullValidator],
      organization		: ['', Validators.nullValidator],
      position		  	: ['', Validators.nullValidator],
      contacts	  	  : ['', Validators.nullValidator]
    };
    if(!this.isEditing){
      items["password"] = ['', [(this.isAnonymous) ? Validators.required : Validators.nullValidator, Validators.minLength(7)]];
    }
    this.userForm	 	=	this.formBuilder.group(items);
  }
  
  createChangePasswordForm(){
    this.userForm	 	=	this.formBuilder.group({
        password	    : ['', [(this.isAnonymous) ? Validators.required : Validators.nullValidator, Validators.minLength(7)]]
    });
  }

  onEditClick() {
    const user 	= this.userData;
    if (!isEmpty(user)) {
		this.userForm.patchValue({
			realname      : user.realname,
			email		      : user.email,
			role		      : (user.role === 'user')? '-1' : user.role,
			mgmt_lev_1	  : user.mgmt_lev_1,
			organization  : user.organization,
			position      : user.position,
			contacts			: (user.contacts && user.contacts.length > 0) ? user.contacts[0].contact : ''
		});
	} else {
		this.userForm.patchValue({
			realname      : '',
			email		      : '',
			role		      : '-1',
			mgmt_lev_1	  : '',
			organization  : '',
			position      : '',
			contacts 			: ''
		});
    }
  }

  getRegions(){
		this.subs.add(	
			this.store.select(selectMap).subscribe(map => {
				if (map) { 
					this.regions = Object.keys(map.regions).map(region=>{ return { value: region, name: region }});			  
				}
			})
		);
	}

  async generateUserExtra($params){
		let extraInfo		= {
			user_id			: $params['info']["id"],
			region			: $params['info']['region'],
      organization: $params['info']['organization']
		}
		let extraResponse = await Promise.resolve( this.userService.saveUserExtra(extraInfo).toPromise() );
		this.isEditing = false;
		this.userForm.reset();
	}

  onSubmitClick() {
    let msgTemplate    = 'TOAST_CREATE_USER';
    let errorTemplate  = 'TOAST_CREATE_USER_ERROR';
		if (!this.isEditing) {
			const reqBody = this.userForm.value;
			if (this.isAnonymous) {
				delete reqBody.role;
			}
			if (isEmpty(reqBody.contacts) || reqBody.contacts === undefined) {
				delete reqBody.contacts;
			}
			this.subs.add(
				this.userService.register(reqBody)
				.subscribe(async response => {
					if(undefined!==response["error"]){
						this.toast.show(ToastType.Danger, response["error"]["message"]);
					} else {
						this.toast.show(ToastType.Success, msgTemplate);
						this.generateUserExtra( { info: { ...response, ...reqBody }, isEditing: this.isEditing });
						this.onModalClose.next(false);
					}
				})
			);
		} else {
			const reqBody 	= this.userForm.value;
			msgTemplate 	= 'TOAST_UPDATE_USER';
			if (reqBody && isEmpty(reqBody.password)) {
				delete reqBody.password;
			}
			if (!this.isApproved) {
				reqBody.active 	= true;
				msgTemplate 	= 'TOAST_APPROVE_USER';
			}
			this.subs.add(
				this.userService.updateUser(reqBody, this.editId).subscribe(
					async response => {
						this.generateUserExtra( { info: { ...response, ...reqBody }, isEditing: this.isEditing });
						this.onModalClose.next(false);
					}
        		)
			);
			this.toast.show(ToastType.Success, msgTemplate);
		}
	}

  onContactSubmitClick(userId, contact) {
		// const {contact} = this.userForm.value;
		if (!isEmpty(contact)) {
			let reqBody = {
				type        : ContactType.Phone,
				user_id     : userId,
				data_source : PostSource.Web,
				contact     : contact
			};
			this.userService.addContacts(reqBody).subscribe(results => {
				console.log("Contact has been updated");
			});
		}
	}
}