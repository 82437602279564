export enum Permission {
  // ManageUsers = 'Manage Users',
  // ManagePosts = 'Manage Posts',
  ManageSettings = 'Manage Settings',
  BulkDataImportAndExport = 'Bulk Data Import and Export',
  // EditTheirOwnPosts = 'Edit their own posts',
  ManageSetupInfo = 'Access/Edit User Server Setup Information',
  AssignSuperUser = 'Assign Super User Roles',
  CreateUser = 'Create Users',
  AssignRoles = 'Assign User Roles',
  DeleteRoles = 'Delete Users',
  ViewNewDeletedAccounts = 'View Created And Deleted Accounts',
  ViewUserActivity = 'View User Activity',
  EditTheirOwnPosts = 'Edit Incident',
  CreatePost = 'Register Incident',
  ViewPosts = 'View Incidents',
  FilterPosts = 'Filters Incident By Category',
  DeletePosts = 'Delete Incident',
  EditPosts = 'Edit Incident',
  LinkUnlinkPosts = 'Link/Unlink Incident',
  ManageCollectionsAndSavedSearches = 'Manage Collections, Saved Searches and Messages',
  ManagePriority = 'Escalate Incident',
  // Client-defined permissions.
  SubmitPosts = 'Submit Posts',

  AccessEVIEWDashboard = 'Acceso a la plataforma',
  CreateReport = 'Crear informe',
  AccessIncidentRiskReports = 'Acceder a informes',
  ViewIncidentRiskReporterIdentity = 'Ver identidad del monitor/a',
  ModifyReportInformation = 'Editar información del informe',
  ModifyPersonalIncidentRiskReports = 'Editar informes personales',
  AccessAnalysisAndFiltersSection = 'Acceder a Análisis y Modelos de Análisis',
  AccessAndModifyParametersSettings = 'Acceder y modificar ajustes',
  ExportIncidentList = 'Exportar lista de incidentes',
  RequestIncidentRiskReports = 'Solicitar modificaciones de incidentes registrados por otros'
}
