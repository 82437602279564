
import {
  BaseModel,
  BaseModelArray,
  BaseModelPagedArray,
  BaseModelPagedRequest
} from '@eview/core/base/base-model';

export interface Risk {
  id                        : number;
  date?                      : string;
  dailyWoredaRisk?           : number;
  yesterdayRisk?             : number;
  twoDaysAgoRisk?            : number;
  threeDaysAgoRisk?          : number;
  fourDaysAgoRisk?           : number;
  fiveDaysAgoRisk?           : number;
  sixDaysAgoRisk?            : number;
  sevenDaysAgoRisk?          : number;
  aggPastRisk?               : number;
  maxPastRisk?               : number;
  aggPastZoneRisk?           : number;
  maxPastZoneRisk?           : number;
  aggAllPastRisk?            : number;
  maxAllPastRisk?            : number;
  inclusivityRisk?           : number;
  transparencyRisk?          : number;
  accountabilityRisk?        : number;
  competitivenessRisk?       : number;
  confidenceInInstitutionRisk? : number;
  forecastedRisk?            : number;
  woreda_context_id?         : number;
  woreda?                    : string;
}

export const Legends = [{1 : 'LOW RISK'}, {2: 'MED-LOW RISK'}, {3:'MED RISK'}, {4:'MED-HIGH RISK'}, {5:'HIGH RISK'}];

export interface Risks extends BaseModelPagedArray<Risk> {}

export interface RiskOptions extends BaseModelPagedRequest {
  startDate?: string;
  endDate?: string;
  type: number;
}