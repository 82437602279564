export enum Type {
  Feature = 'Feature',
  FeatureCollection = 'FeatureCollection',
  Geometry = 'Geometry',
  GeometryCollection = 'GeometryCollection',
  Point = 'Point'
}

export interface GeoJsonObject {
  coordinates: number[];
  features?: GeoJsonObject[];
  fileName?: string;
  geometry?: GeoJsonObject;
  geometries?: GeoJsonObject[];
  properties?: {
    title?: string;
    descrition?: string;
    color?: string;
    icon?: string;
    DEP?: number;
    url?: string;
    Departamen?: string;
    NOMBRE?: string;
    Ward?: string;
    ConstName1?: string;
    ADM1_PCODE?: string;
    ADM2_PCODE?: string;
    ADM3_PCODE?: string;
    style?: any;
    name: string;
    count?: number;    
  };
  total?: number;
  type: Type;
}

export const DepartmentList = {
    1: "Atlántida",
    2 : "Colón",
    3 : "Comayagua",
    4 : "Copán",
    5 : "Cortés",
    6 : "Choluteca",
    7 : "El Paraíso",
    8 : "Francisco Morazán",
    9 : "Gracias a Dios",
    10 : "Intibucá",
    11 : "Islas de la Bahía",
    12 : "La Paz",
    13 : "Lempira",
    14 : "Ocotepeque",
    15 : "Olancho",
    16 : "Santa Bárbara",
    17 : "Valle",
    18:  "Yoro"
};
