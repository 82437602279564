import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PostHelpers } from '@eview/core/domain/post/post.helpers';
import { Post, PostStatus, PostPriority } from '@eview/core/domain/post/post';
import { UsersHelpers } from '@eview/core/users/users.helpers';
import { environment } from '@eview/core';
import { AppState } from '@eview/core/store/states/app.state';
import { Store } from '@ngrx/store';
import { selectUsers } from '@eview/core/store/selectors/users.selector';
import { map } from 'rxjs/operators';
import { Permission } from '@eview/core/auth/permission';
import { AuthHelpers } from '@eview/core/auth';
import { Observable } from 'rxjs';
import { User } from '../../../../../../../../libs/core/models/user';
import { PostType } from '@eview/core/domain/post/post';

@Component({
  selector: 'eview-post-subtitle',
  templateUrl: 'post-subtitle.component.html',
  styleUrls: ['post-subtitle.component.scss']
})
export class PostSubtitleComponent {
  constructor(store: Store<AppState>) {
    this.store = store
    this.user$ = this.store
    .select(selectUsers)
    .pipe(
      map(users =>
        this.post.user && this.post.user.id
          ? UsersHelpers.FindUserById(users, this.post.user.id)
          : null
      )
    );
  }

  @Input() post: Post;
  @Input() disableLinkPost: Boolean;
  @Output() public onShowLinkedPostClick: EventEmitter<Object> = new EventEmitter();

  PostHelpers = PostHelpers;

  UsersHelpers = UsersHelpers;

  PostStatus = PostStatus;

  PostPriority = PostPriority;

  format = environment.format;

  AuthHelpers = AuthHelpers;
  
  Permission = Permission;
  store: Store<AppState>;
  user$: Observable<User>;

  PostType = PostType;

  get managementLevels() {
    return PostHelpers.FormatMgmtLevels(this.post);
  }

  onShowLinkedClick(post: Post) {
    //event.stopPropagation();
    this.onShowLinkedPostClick.emit({id : post.id, title: post.title});
  }

}
