import { BaseModel, BaseModelArray } from '@eview/core/base/base-model';
import { User } from '@eview/core/models/user';
import { PostStatus } from '../post/post';

export interface ExportJobRequest {
  entity_type: ExportEntityType;
  filters?: any;
  lang?: string;
}

export interface ExportJob extends BaseModel {
  id: number;
  user: User;
  status: ExportJobStatus;
  url_expiration: number;
}

export interface ExportJobs extends BaseModelArray<ExportJob> {}

export enum ExportJobStatus {
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Success = 'SUCCESS',
  Failed = 'FAILED'
}

export enum ExportEntityType {
  Post = 'post',
  User = 'user'
}
