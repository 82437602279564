import {
  Component,
  Input,
  AfterViewInit,
  OnInit,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@eview/core/store/states/app.state';
import * as L from 'leaflet';
import { GraphBaseComponent } from '@eview/features/ui/post-editor/graph-base.component';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { isEmpty, cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
const MAP_ID: string = 'region-map';
const VIEW_MAP_ID = 'map-view';

@Component({
  selector: 'eview-post-graph',
  templateUrl: 'post-graph.component.html',
  styleUrls: ['post-graph.component.scss']
})
export class PostGraphComponent extends GraphBaseComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  constructor(store: Store<AppState>, spinner: SpinnerVisibilityService) {
    super(store, spinner);
  }

  @Input() data: any[];
  @Input() index: number;
  @Input() isSingle: boolean;
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() legendPosition: string;
  @Input() type: number;
  @Input() legendTitle: string;

  mapId: string = MAP_ID;
  private firstLoadFlag: boolean = true;
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  showDataLabel: boolean = true;
  layers: any = [];
  subs: Subscription;

  ngOnInit() {
    this.subs = new Subscription();
    this.mapId = this.mapId + '_' + this.index;
  }

  ngOnChanges() {
    if (this.type === 4) {
      this.showHideMap();
    } else {
      this.onMapContainerShowHide(false);
    }
  }

  ngOnDestroy() {
    this.layers.forEach((layer) => {
      layer.remove();
      this.map.removeLayer(layer);
    });
    if (this.map) {
      this.map.removeEventListener('click');
      this.map.remove();
      this.map.off();
      this.map = null;
    }  
    this.layers = [];
    this.store = null;
    this.subs.unsubscribe();
  }


  onMapContainerShowHide(flag) {
    this.isSingle = false;
    let mapContainer = document.getElementById('report-map-container');
    if (mapContainer) {
      mapContainer.style.display = flag ? 'block' : 'none';
    }
  }

  showHideMap() {
    if (!isEmpty(this.data)) {
      this.addToolTipsToRegions();
    }
    this.onMapContainerShowHide(true);
  }

  onEachFeature(feature, layer) {
    layer.on({
      mouseover: this.highlightFeature.bind(this),
      mouseout: this.resetHighlight.bind(this),
      click: this.zoomToFeature.bind(this)
    });
  }

  getColor(d) {
    return d >= 100
      ? '#710202'
      : d >= 50
      ? '#B50000'
      : d >= 20
      ? '#D25B00'
      : d >= 10
      ? '#DF9E11'
      : d >= 5
      ? '#ECDA36'
      : d >= 2
      ? '#C0E8AB'
      : '#ffffff';
  }

  getRegionStyle(feature) {
    return {
      fillColor: this.getColor(feature.properties.count),
      weight: 2,
      opacity: 1,
      color: 'grey',
      dashArray: '3',
      fillOpacity: 0.7,
      innerHTML: feature.properties.name
    };
  }


  addLegends() {
      let legend = L.control.attribution({ position: 'bottomright' });
      legend.onAdd = map => {
        let div = L.DomUtil.create('div', 'info legend'),
          grades = [2, 5, 10, 20, 50, 100];
        div.style.display = "flex";
        div.style.flexDirection = "column";
        // loop through our density intervals and generate a label with a colored square for each interval
        for (let i = 0; i < grades.length; i++) {
          div.innerHTML +=
            '<div style="margin: 5px"><i class="custom-legend" style="background:' +
            this.getColor(grades[i]) +'"></i> ' +
            grades[i] +
            (grades[i + 1] ? '&ndash;' + grades[i + 1] + '<br>' : '+');
        }
        div.innerHTML += '</div>';
        div.style.lineHeight = '18px';
        div.style.color = '#555';
        return div;
      };
      legend.addTo(this.map);
  }

  addToolTipsToRegions() {
    let layerData = [];
    this.layers = [];
    // this.mapLayerControl.remove();
    this.layersMetaData.map((d1, index) => {
      const features = cloneDeep(d1.features);
      layerData = features.map((item) => {
          if (this.data && this.data[index] !== undefined) {
            let name: string = '';
            const reportCount = this.data[index].filter(region => {
              let flag: boolean = false;
              if (item.properties && item.properties.NOMBRE) {
                flag = (region.name.toLowerCase() == item.properties.NOMBRE.toLowerCase());
                name = item.properties.NOMBRE;
              } else {
                flag = (region.name.toLowerCase() == item.properties.Departamen.toLowerCase());
                name = item.properties.Departamen;
              }
              return flag;
            });
            item.properties.name = name;
            item.properties.count = (reportCount && reportCount[0])? reportCount[0].count : 0;
        }        
        return item;
      });
      this.layer = L.geoJSON(layerData as any, {
      style: this.getRegionStyle.bind(this),
      onEachFeature: this.onEachFeature.bind(this)
      }).bindTooltip((layer: any) => {
        let tooltip = '';
        if (index === 0) {
          tooltip = layer.feature.properties.Departamen;
        } else if (index === 1) {
          tooltip = layer.feature.properties.NOMBRE;
        }
        tooltip = tooltip + '(' + layer.feature.properties.count + ')';
        return tooltip;
      });
      const config = this.configs.find(c => c.fileName === d1.fileName) || {
                  base: false,
                  selected: false,
                  options: null,
                  listenForClick: null
                };
              this.mapLayerControl.addOverlay(
                this.layer,
                config.altName || d1.fileName
              );
            this.layers.push(this.layer);
          
    });
    if (this.layers && this.layers.length > 0) {
      this.layers[0].addTo(this.map);
    }
  }

  ngAfterViewInit() {
    this.buildMap();
    this.addLegends();
    this.addShapefiles();    
    this.onMapContainerShowHide(false);
  }
}
