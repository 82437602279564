import * as moment from 'moment';
import { isEmpty, cloneDeep, groupBy } from 'lodash';
import { environment } from '../../environments/environment';
import { ReportRecordType, FormFields } from '@eview/core/domain/filters/filterCriteria';
import { PostHelpers } from '@eview/core/domain/post/post.helpers';
import {
  PostStatus,
  PostPriority,
  PostFilters,
  RiskFilters,
  FormFilters,
  FilterItem,
  PostStatusTranlasted
} from '@eview/core/domain/post/post';

const GetStartEndDates = (options: Object) => {
  let startDate, endDate, dateFormat = 'YYYY-MM-DD';

  endDate = moment(new Date()).format(dateFormat);
  if (options['duration'] && isEmpty(options['duration'])) {
    return { startdate: null, enddate:  moment(new Date()).format(dateFormat)};
  }
  switch (parseInt(options['duration'])) {
    case 1:
      startDate = moment(new Date()).subtract('1', 'day').format(dateFormat);
      endDate = moment(new Date()).format(dateFormat);
      break;
    case 2:
      startDate = moment(new Date())
        .subtract('7', 'days').format(dateFormat);
      break;
    case 3:
      startDate = moment(new Date())
        .subtract('30', 'days').format(dateFormat);
      break;
    case 4:
      let fromDate = options['fromdate']
        ? options['fromdate']
        : options['startDate'];
      let todate = options['todate'] ? options['todate'] : options['endDate'];

      if (fromDate && todate) {
        startDate = moment(
          new Date(
            fromDate.year + '-' + fromDate.month + '-' + fromDate.day
          )
        ).format(dateFormat)

        endDate = moment(
          new Date(
            todate.year + '-' + todate.month + '-' + todate.day
          )
        ).add('1', 'day').format(dateFormat);
      }
      break;
      default:
        startDate = moment(new Date()).format();
        endDate = moment(new Date()).format();
  }
  return { startdate: startDate, enddate: endDate };
};

const CreateMetaData = (infoData: any, configFilters: object) => {
  if (!infoData) {
    return '';
  }
  const config = configFilters['config'];
  const formAttributes = configFilters['formAttributes'];

  const durationType = cloneDeep(
    config[1].values.filter(item => {
      return item.id == infoData['duration'];
    })
  );
  // get region of management level is regional
  const mgmtLabel = config[0].values.filter(ele => {
    return ele.id == infoData.management_level;
  });
  const region = infoData['region'] ? infoData['region'] + ' - ' : '';
  const firstPart = mgmtLabel[0].name + ' : ' + region.toUpperCase();
  // check type category selected
  const category = formAttributes.results.filter(item => {
    return item.key === infoData['category'];
  });
  const secondPart =
    category[0] && category[0].label ? category[0].label + ' ' : '';
  // Format custom date to show range
  if (parseInt(infoData['duration']) === 4) {
    const startDate = moment(
      new Date(
        infoData.startDate.year +
          '-' +
          infoData.startDate.month +
          '-' +
          infoData.startDate.day
      )
    ).format(environment.format.date);
    const endDate = moment(
      new Date(
        infoData.endDate.year +
          '-' +
          infoData.endDate.month +
          '-' +
          infoData.endDate.day
      )
    ).format(environment.format.date);
    durationType[0].name = startDate + ' - ' + endDate;
  }
  const thirdPart = durationType[0] ? '(' + durationType[0].name + ')' : '';
  return {'title' : firstPart + secondPart + thirdPart, 'category': secondPart};
};

const CreateGraph = (data: any, type: number) => {
  if (type === 2 || type === 4) {
    const reportData = data.map(record => {
      if (record) {
        return {
          name: record.label,
          value: record.total
        };
      }
    });
    return reportData;
  } else if (type === 3) {
    let processedData: any = [];
    let temp = groupBy(data, 'label');
    if (!isEmpty(temp)) {
      Object.keys(temp).forEach(record => {
        let series: object[] = [];
        temp[record].map(rec => {
          series.push({
            value: rec.total,
            name: moment(new Date(rec.time_label * 1000))
          });
        });
        processedData.push({ name: record, series: series });
      });
    }
    return processedData;
  }
};

const GetFormattedMapData = (posts): any => {
    const regionDataGroup = groupBy(posts.results, 'mgmt_lev_1');
    const zoneDataGroup = groupBy(posts.results, 'mgmt_lev_2');

    let regionGroup = [],
      zoneGroup = [], woredaGroup = [];
    for (let area in regionDataGroup) {
      regionGroup.push({ name: area, count: regionDataGroup[area].length });
    }
    for (let area in zoneDataGroup) {
      zoneGroup.push({ name: area, count: zoneDataGroup[area].length });
    }
    return [regionGroup, zoneGroup];
};

const GetPostRequestOptions = (criteria: any) => {
  let options;
  const dates = GetStartEndDates(criteria);
  options = {
    startDate: dates.startdate,
    endDate: dates.enddate,
    formId: (criteria) ? criteria.formId : environment.form.id
  };
  return options;
};

const CreateTable = (data: any) => {
  if (data.length > 0) {
    const reportData = data.map(record => {
      if (record) {
        return {
          name: record.label,
          value: record.total
        };
      }
    });
    return reportData;
  }
};

const GetAllReportColumns = (mapRegions, tags) =>  {
    if (mapRegions) {
      let processedData: ReportRecordType[] = [];
      let categories = Object.values(tags);
      let temp: ReportRecordType = {} as any;
      for (let key in FormFields) {
        temp[FormFields[key]] = {"type": "string"};
      }
    processedData.push(temp);
      for (let region in mapRegions) {
        let temp: ReportRecordType = {} as any;
        for (let key in FormFields) {
          temp[FormFields[key]] = '';
          if (key === 'REGION') {
            temp[FormFields['REGION']] = region;
          }
        }
        let cat = categories.slice(0, 1);
        temp[FormFields['TYPE_OF_INCIDENT']] = (cat) ? [cat] : '';
        categories.splice(0, 1);
        processedData.push(temp);
      };
      return processedData;
    }
}

const GetAllColumnData = (params) =>  {
    const {reportItem, tags, attributes, formId }  = params;
    if (reportItem && reportItem !== undefined) {
      let individualItem: any = {};
        individualItem[FormFields.PRIORITY] = reportItem.priority;
        individualItem[FormFields.DATE] = moment(reportItem.post_date).format(environment.format.date);
        individualItem[FormFields.TITLE] = reportItem.title;
        if (reportItem && !isEmpty(reportItem.mgmt_lev_1)) {
          individualItem[FormFields.REGION] = reportItem.mgmt_lev_1;
        }
        if (reportItem && !isEmpty(reportItem.mgmt_lev_2)) {
          individualItem[FormFields.ZONE] = reportItem.mgmt_lev_2;
        }

        if (reportItem && !isEmpty(reportItem.status)) {
          individualItem[FormFields.INCIDENT_STATUS] = PostStatusTranlasted[reportItem.status];
        }

        if (reportItem && reportItem.values) {
          Object.keys(reportItem.values).map(category => {
            if (attributes && attributes.length > 0) {
              const keyFound = attributes.filter(item => {
              return item.key === category;
              });
              if (!isEmpty(keyFound)) {
                const val = reportItem.values[category][0];
                if (val !== '-1') {
                  individualItem[keyFound[0].label] =
                  keyFound[0].type === 'tags' ? tags[val] : val;
                }
              }
            }
          });
        }
      return individualItem;
    }
}


const GetReport = (results, criteriaList, configAttributes: object) => {
  let allReports = [];
  if (results.length > 0) {
    results.forEach((value, index) => {
      if (value && criteriaList[index]) {
        let report: any = {};
        const metadata = CreateMetaData(criteriaList[index], configAttributes);
        report.title = metadata['title'];
        report.category = metadata['category'];
        if (value) {
          const reportType = parseInt(criteriaList[index]['format']);
          report.type = reportType;
          if (reportType && reportType === 1) {
            report.data = CreateTable(value);
          } else {
            report.data = CreateGraph(value, reportType);
          }
        }
        allReports.push(report);
      }
    });
    return allReports;
  }
};

const GetFilterLabels = (label: string): string => {
  if (label) {
    switch (label) {
      case PostFilters.Incident_Status:
        return 'FILTER_LABEL_STATUS';
      case PostFilters.Priority:
        return 'FILTER_LABEL_PRIORITY';
      case PostFilters.Province:
        return 'FILTER_LABEL_MGMT_LEV_1';
      case PostFilters.District:
        return 'FILTER_LABEL_MGMT_LEV_2';
      case PostFilters.Categories:
        return 'FILTER_TAGS';
      case FormFilters.Target:
        return 'FILTER_LABEL_TARGET';
      case FormFilters.Target_General:
        return 'FILTER_LABEL_TARGET_GENERAL';
      case FormFilters.Source_of_information:
      return 'FILTER_LABEL_SOURCE_OF_INFO';
      case FormFilters.Means:
      return 'FILTER_LABEL_MEANS';
      case FormFilters.Status:
      return 'FILTER_LABEL_INCIDENT_STATUS';
      case FormFilters.Perpetrators:
      return 'FILTER_ALLEGED_PERPETRATORS';
      case RiskFilters.TYPE_OF_LOCATION:
      return 'FILTER_TYPE_OF_LOCATION';
      case RiskFilters.PROVINCE:
      return 'FILTER_GENERAL_REGION';
      case RiskFilters.WARD:
      return 'FILTER_GENERAL_WOREDA';
      case RiskFilters.DISTRICT:
      return 'FILTER_GENERAL_ZONE';      
      default: return '';
    }
  }
};

const GetEnumValues = (enumVal: any) => {
  if (enumVal) {
    let temp = [];
    Object.keys(enumVal).forEach((value) => {
      temp.push(enumVal[value]);
    });
    return temp;
  }
  return [];
};

const GetFilterOptionsByKey = (name: string, regions: object[]): string[] => {

    let keys, values;
  switch (name) {
      case PostFilters.Incident_Status:
        keys = GetEnumValues(PostStatus);
        values = [];
        keys.forEach((item: PostStatus) => {
          const obj = {
            name: PostHelpers.GetStatusLabel(item),
            value: item
          }
          values.push(obj);
        });
        return values;
      case PostFilters.Priority:
        keys = GetEnumValues(PostPriority);
        values = [];
        keys.forEach((item: PostPriority) => {
          const obj = {
            name: PostHelpers.GetPostPriorityLebel(item),
            value: item
          }
          values.push(obj);
        });
        return values;
      case PostFilters.Province:
        values = [];
        if (!isEmpty(regions)) {
          Object.keys(regions).forEach((item) => {
            const obj = {
              name: item,
              value: item
            }
            values.push(obj);
          });
        }
        
        return values;
      case PostFilters.District:
        return [];
      case PostFilters.Categories:
        return [];
      default: return [];
    }
};

const GetFilters = (regions) : any => {
    let filters: FilterItem[] = [];
    const filterKeys = GetEnumValues(PostFilters);
    if (filterKeys && filterKeys.length > 0) {
      filterKeys.forEach((key) => {
        const filterItem: FilterItem = {
          label: GetFilterLabels(key),
          queryParam: key,
          values: GetFilterOptionsByKey(key, regions)
        }
        filters.push(filterItem);
      });
    }
    return filters;
};

const GetDateFromObject = (datePassed: any) => {
  const { year, month, day } = datePassed;
  return moment(new Date(year, month - 1, day)).format('YYYY-MM-DD');
}

export const FilterHelpers = {
  GetStartEndDates,
  GetReport,
  GetPostRequestOptions,
  GetAllReportColumns,
  GetFilters,
  GetFormattedMapData,
  GetAllColumnData,
  GetDateFromObject,
  GetFilterLabels
};
