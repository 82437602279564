import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { environment } from '@eview/core';
import { AuthHelpers } from '@eview/core/auth';
import { Permission } from '@eview/core/auth/permission';
import { GeoJsonObject, DepartmentList } from '@eview/core/domain/post/geo-json';
import { Marker, Position } from '@eview/core/models/map';
import {
  EMapActions,
  SetMapDefaults,
  SetMapRegion,
  SimulateUserClickedMap,
  UserClickedMap,
  UserClickedMarker,
  UserClickedCommentMap
} from '@eview/core/store/actions/map.actions';
import { selectMap } from '@eview/core/store/selectors/map.selector';
import { AppState } from '@eview/core/store/states/app.state';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as happen from 'happen';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import * as lodash from 'lodash';
import { Subscription } from 'rxjs';
import * as shp from 'shpjs';
import * as mapIcons from './icons';

const MAP_INITIAL_CENTER: L.LatLngExpression = [0, 0];
const MAP_INITIAL_ZOOM: number = 1;
const MAP_ZOOM_POSITION: L.ControlPosition = 'bottomleft';
const MAP_LAYER_POSITION: L.ControlPosition = 'bottomleft';
const MAP_URL_TEMPLATE: string =
  'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const MAP_ZOOM_MAX: number = 19;
const MAP_ATTRIBUTION: string =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>';
const CREATE_MAP_ID = 'post-create-map';
const VIEW_MAP_ID = 'map-view';
const MOBILE_MAP_ID = 'map-view-mobile';
const MAP_VIEW_DETAIL = 'map-view-detail';

@Component({
  selector: 'eview-map',
  template: `
    <div class="map-container" id="map-container" style="height: 100%;">
      <div class="map-frame" style="height: 100%;">
        <div [id]="mapId" style="height: 100%; z-index: 0;"></div>
      </div>
    </div>
  `
})
export class MapComponent implements AfterViewInit, OnDestroy {

  @Input() disabled: boolean = false;

  @Input() marker: Position;

  @Input() getMarkersFromStore: boolean = false;

  @Input() mapId: string;

  private layers: any = [];

  private map: L.Map;

  private mapLayerControl: L.Control.Layers;

  private initiated: boolean = false;

  private userCanClick: boolean = false;

  private userCursorMarker: L.Marker = null;

  private userCursorProperties: any;

  private markers: Marker[];

  private mapMarkersCluster: L.MarkerClusterGroup;

  private clickedMapMarker: L.Marker;

  private initialZoom: number = null;

  private subs: Subscription;

  private isMapClick: boolean = false;

  layerGroup: L.LayerGroup;

  AuthHelpers = AuthHelpers;

  ListenerHandle: any;

  markerHandler: any;

  mapMarkerHandler: any;
  
  Permission = Permission;
  store: Store<AppState>
  constructor(store: Store<AppState>, private actions$: Actions) {
    this.store = store;
    this.subs = new Subscription();
    this.layers = [];
    setTimeout(() => {
        if (this.mapId == MOBILE_MAP_ID) {
          this.map.invalidateSize();
        }
    }, 500);
  }

  ngAfterViewInit() {
    this.buildMap();
    this.initMap();
    this.listenToMapEvents();
    this.addShapefiles();
    this.handleAuthorization();
    this.subs.add(
    this.store.select(selectMap).subscribe(map => {
      if (!map) return;

      if (
        !this.disabled &&
        this.userCanClick &&
        !this.userCursorMarker        
      ) {
        let tempLatLon;
        if (this.marker && this.marker.lat && this.marker.lon) {
          tempLatLon =  { lat: this.marker.lat, lon: this.marker.lon };
        } else if (map.actual && map.actual.lat && map.actual.lon) {
          tempLatLon = { lat: map.actual.lat, lon: map.actual.lon };
        }
        this.userCursorMarker = this.addMarkerToMap(
          tempLatLon,
          this.map,
          mapIcons.userCursorIcon
        );
      }

      if (!this.marker && this.getMarkersFromStore && map.markers) {
        if (!lodash.isEqual(map.markers, this.markers)) {
          this.markers = map.markers;
          if (this.mapMarkersCluster)
            this.mapMarkersCluster.removeFrom(this.map);
          const mapMarkers = map.markers.map(m => this.buildMapMarker(m));
          mapMarkers.forEach(m =>
            m.addEventListener('click', event => {
              this.mapMarkerReportClick(event);
            })
          );
          this.mapMarkersCluster = new L.MarkerClusterGroup();
          this.mapMarkersCluster.addLayer(L.layerGroup(mapMarkers));
          this.map.addLayer(this.mapMarkersCluster);
        }
      }
    })
    );

    this.subs.add(
      this.actions$
        .pipe(ofType<UserClickedCommentMap>(EMapActions.UserClickedCommentMap))
        .subscribe(action => {
          if (this.mapMarkersCluster) {
            const layers = this.mapMarkersCluster.getLayers();
            if (layers.length > 0) {
              const selectedMap = layers.filter((item: any) => {
                return item.feature.properties.id == action.payload;
              });
              this.mapMarkerReportClick({ target: selectedMap[0] });
            }
          }
        })
    );

    this.subs.add(
      this.actions$
        .pipe(
          ofType<SimulateUserClickedMap>(EMapActions.SimulateUserClickedMap)
        )
        .subscribe(action => {
          if (!action.payload || (action.payload && !action.payload.actual)) {
            if (this.userCursorMarker) {
              this.userCursorMarker.removeFrom(this.map);
              this.userCursorProperties = null;
            }
            return;
          }
          const { lat, lon, zoom } = action.payload.actual;
          const tempMarker = this.addMarkerToMap(
            { lat, lon },
            this.map,
            mapIcons.userCursorIcon
          );
          const boundingRect = tempMarker.getElement().getBoundingClientRect();
          const [offsetLeft, offsetTop] = mapIcons.userCursorIcon.options
            .iconAnchor as [number, number];
          const left = boundingRect.left + offsetLeft / 2;
          const top = boundingRect.top + offsetTop;
          tempMarker.removeFrom(this.map);
          const ele = document.elementFromPoint(left, top);
          if (ele) {
            happen.click(ele, {
              type: 'click',
              clientX: left,
              clientY: top
            });
          }
        })
    );
  }

  ngOnDestroy() {
    if (this.layerGroup) {
      this.layerGroup.clearLayers();
    }
    if (this.ListenerHandle) {
      this.ListenerHandle = null;
    }
    if (this.markerHandler) {
      this.markerHandler = null;
    }
    this.layers.forEach((layer) => {
      layer.remove();
      this.map.removeLayer(layer);
    });
    if (this.map) {
      this.map.removeEventListener('click');
      this.map.remove();
      this.map.off();
      this.map = null;
    }  
    this.layers = [];
    this.store = null;
    this.subs.unsubscribe();
  }

  
  private buildMapMarker(marker: Marker, icon: L.Icon<any> = null): L.Marker {
    if (!icon) {
      if (marker.parent && marker.parent.color && marker.parent.icon)
        icon = mapIcons.customIcon(marker.parent);
      else if (marker.parent && (marker.parent.color || marker.parent.icon))
        icon = mapIcons.customIcon(marker.parent);
      else icon = mapIcons.defaultIcon(marker.type);
    }
    const m = L.marker({ lat: marker.lat, lng: marker.lon }, { icon: icon });
    m.feature = { type: null, geometry: null, properties: marker.parent };
    return m;
  }

  private addMarkerToMap(
    marker: Marker,
    map: L.Map,
    icon: L.Icon<any> = undefined
  ): L.Marker {
    let showMarker = null;
    if (this.isMapClick || marker) {
      showMarker = this.buildMapMarker(marker, icon);
      showMarker.addTo(map);
    }
    this.isMapClick = false;
    return showMarker;
  }

  private buildMap() {

    let mapOptions = {
      center: MAP_INITIAL_CENTER,
      zoom: MAP_INITIAL_ZOOM,
      zoomControl: false,
      scrollWheelZoom: false,
      zoomSnap: 0.25
    };
     
    this.map = L.map(this.mapId, mapOptions);    
    this.map.addControl(L.control.zoom({position: MAP_ZOOM_POSITION}));
    L.tileLayer(MAP_URL_TEMPLATE, {
      maxZoom: MAP_ZOOM_MAX,
      attribution: MAP_ATTRIBUTION
    }).addTo(this.map);
    if (this.mapId === VIEW_MAP_ID) {
      this.mapLayerControl = L.control
      .layers(null, null, { position: MAP_LAYER_POSITION, hideSingleBase: false })
      .addTo(this.map);
    }
  }

  private initMap() {
    this.subs.add(
        this.store.select(selectMap).subscribe(map => {
        if (!map || this.initiated) return;
        const defaultZoom = (this.mapId === MAP_VIEW_DETAIL || this.mapId === MOBILE_MAP_ID) ? (map.defaults.zoom - ((this.mapId === MOBILE_MAP_ID)? 1 : 2)) : map.defaults.zoom;
        this.map.setView([map.defaults.lat, map.defaults.lon], defaultZoom);
        this.initiated = true;
        this.initialZoom = defaultZoom;
        this.map.invalidateSize();
      })
    );
    this.subs.add(
      this.actions$
      .pipe(ofType<SetMapDefaults>(EMapActions.SetMapDefaults))
      .subscribe(actions => {
        if (!actions.payload || this.initiated) return;
        this.map.setView(
          [actions.payload.defaults.lat, actions.payload.defaults.lon],
          actions.payload.defaults.zoom
        );
        this.initiated = true;
      })
    );
  }

  private listenToMapEvents() {

    this.ListenerHandle = (event: L.LeafletMouseEvent) => {
        this.unclickMapMarker();
        if (this.userCursorMarker && this.isMapClick) {
           this.userCursorMarker.removeFrom(this.map);
        }
        if (this.userCanClick && this.isMapClick) {
          this.userCursorMarker = this.addMarkerToMap(
            { lat: event.latlng.lat, lon: event.latlng.lng },
            this.map,
            mapIcons.userCursorIcon
          );
          if (this.userCursorProperties) {
            this.userCursorProperties['Departamen'] = (this.userCursorProperties.DEP) ? DepartmentList[this.userCursorProperties.DEP] : null;
          }
          this.store.dispatch(
            new UserClickedMap({
              actual: {
                lat: event.latlng.lat,
                lon: event.latlng.lng,
                properties: this.userCursorProperties || null
              }
            })
          );
        }
    };
    if (!this.disabled) {
      this.map.addEventListener('click', this.ListenerHandle, true);
    }
  }

  private addShapefiles() {
    let queue: Promise<any>[] = environment.map.shapeFiles.map(
      sf => shp(sf.uri) as Promise<any>
    );
    const configs = environment.map.shapeFiles.reduce(
      (cfgs, sh) => [...cfgs, ...sh.config],
      []
    );
    let regionGroupBy;
    Promise.all(queue).then(data => {
      let i = 1, regionList = [];
      data.forEach((d) => {
          d.forEach((d1: GeoJsonObject, index) => {
            if (index === 1) {
              if (this.mapId === VIEW_MAP_ID) {
                  if (d1.features) {
                    d1.features.map((region) => {
                      const regionName = region.properties;
                      const obj = {
                        'departamentos': (DepartmentList[regionName.DEP]) ? DepartmentList[regionName.DEP] : '',
                        'municipios': regionName.NOMBRE,
                      };
                      regionList.push(obj);
                      region = null;
                    });
                  }
                  regionGroupBy = lodash.groupBy(regionList, 'departamentos');
                  this.store.dispatch(new SetMapRegion({'regions': regionGroupBy}));
                  regionList = null;
              }
            }
              const config = configs.find(c => c.fileName === d1.fileName) || {
                base: false,
                selected: false,
                options: null,
                listenForClick: null
              };
              let layer = L.geoJSON(d1 as any, config.options);
              if (config.base && this.mapId === VIEW_MAP_ID) {
                  this.mapLayerControl.addOverlay(
                    layer,
                    config.altName || d1.fileName
                  );
              }
              this.markerHandler = (event: L.LeafletMouseEvent) => {
                  this.isMapClick = true;
                  if (config.listenForClick.dispatchProperties)
                    this.userCursorProperties = {
                      ...this.userCursorProperties,
                      ...event.layer.feature.properties
                    };
                };
              if (config.listenForClick && config.listenForClick.enabled) {
                layer.addEventListener('click', this.markerHandler, true);
              }
              this.layers.push(layer);
              layer = null;
          });
      });
      if (this.layers && this.layers.length > 0) {
        if (this.mapId === CREATE_MAP_ID) {
          this.layers[1].addTo(this.map);
        } else {
          this.layers[0].addTo(this.map);
        }
      }
      data.length = 0;
    }).catch((error) => {
        console.log(error);
    });
  }

  private handleAuthorization() {
    this.subs.add(
      AuthHelpers.User.HasUserPermission(
        this.store,
        Permission.CreateReport
      ).subscribe(can => {
        if (this.userCanClick !== can && this.userCursorMarker)
          this.userCursorMarker.removeFrom(this.map);
        this.userCanClick = can;
      })
    );
  }

  private unclickMapMarker() {
    if (!this.clickedMapMarker) return;
    this.clickedMapMarker.setIcon(
      mapIcons.customIcon(this.clickedMapMarker.feature.properties)
    );
  }

  private mapMarkerReportClick(event) {
    this.unclickMapMarker();
    if (this.userCursorMarker) this.userCursorMarker.removeFrom(this.map);
    let marker = event.target as L.Marker;
    marker.setIcon(mapIcons.clickedIcon);
    this.clickedMapMarker = marker;
    this.clickedMapMarker.feature.properties.icon =
      this.clickedMapMarker.feature.properties.icon ||
      environment.defaults.tagIcon;
    this.clickedMapMarker.feature.properties.color =
      this.clickedMapMarker.feature.properties.color ||
      environment.defaults.tagColor;
    const parent = marker.feature.properties || null;
    this.store.dispatch(
      new UserClickedMarker({
        lat: marker.getLatLng().lat,
        lon: marker.getLatLng().lng,
        parent: { id: parent.id || null }
      })
    );
    marker = null;
  }
}
