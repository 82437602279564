import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@eview/core';
import {
  PostComment,
  PostCommentUser,
  PostCommentType,
  LinkPost
} from '@eview/core/domain/post/post-comment';
import { PostCommentHelpers } from '@eview/core/domain/post/post-comment.helpers';
import { PostService } from '@eview/core/domain/post/post.service';
import { User } from '@eview/core/models/user';
import { AppState } from '@eview/core/store/states/app.state';
import { Store } from '@ngrx/store';
import { UserClickedCommentMap } from '@eview/core/store/actions/map.actions';
import { cloneDeep, find } from 'lodash';
import { Observable, BehaviorSubject } from 'rxjs';
import { CreatedPostComment } from '../../custom.store';
import { AuthHelpers } from '@eview/core/auth';
import { Permission } from '@eview/core/auth/permission';
import { selectUser } from '@eview/core/store/selectors/user.selector';

@Component({
  selector: 'eview-post-comment',
  templateUrl: 'post-comment.component.html',
  styleUrls: ['post-comment.component.scss']
})
export class PostCommentComponent implements OnInit {
  user$: Observable<User>

  constructor(
    private store: Store<AppState>,
    private postService: PostService
  ) {}

  ngOnInit() {
    this.comment.users = this.comment.users || [];
    this._comment = cloneDeep(this.comment);
    this.isEditing = !this.comment.id;
    this.user$ = this.store.select(selectUser);
  }

  @Input() public comment: PostComment;
  @Input() public isParent: boolean;
  @Input() public user: User;
  @Input() public type: string;
  private _comment: PostComment;

  PostCommentHelpers = PostCommentHelpers;

  format = environment.format;

  Permission = Permission;

  isEditing: boolean;

  openModal = new BehaviorSubject(false);

  selectedUser: User;

  PostCommentType = PostCommentType;

  onActionTakenClick() {
    setTimeout(() => {
      if (!this.comment.action_taken && !this.comment.no_action_taken) return;
      this.comment.no_action_taken = !this.comment.action_taken;
    });
  }

  onNoActionTakenClick() {
    setTimeout(() => {
      if (!this.comment.action_taken && !this.comment.no_action_taken) return;
      this.comment.action_taken = !this.comment.no_action_taken;
    });
  }

  onUserDelete(user: PostCommentUser) {
    this.comment.users = this.comment.users.filter(u => u.id !== user.id);
  }

  onUserAdd() {
    if (!this.selectedUser || typeof this.selectedUser !== 'object') return;
    this.comment.users = [...this.comment.users, this.selectedUser];
    this.selectedUser = null;
  }

  onCancelClick() {
    this.comment = cloneDeep(this._comment);
    this.isEditing = false;
    if (!this.comment.id) this.comment = null;
  }

  onSaveClick() {
    const obs = this.comment.id
      ? this.postService.updateComment(this.comment)
      : this.postService.createComment(this.comment);
    obs.subscribe(comment => {
      if (!this.comment.id)
        this.store.dispatch(new CreatedPostComment(comment));
      this.comment = comment;
      this._comment = cloneDeep(this.comment);
      this.isEditing = false;
    });
  }

  onRemoveClick(data: LinkPost) {
    this.comment.linked_reports = this.comment.linked_reports.filter((item) => {
      return item.id !== data.id;
    });
  }

  onPostClick(item) {
    this.store.dispatch(
      new UserClickedCommentMap(
        item.id
      )
    );
  }

  onAddClick() {
    this.openModal.next(true);
  }

  onCloseClick() {
    this.openModal.next(false);
  }

  onApplyClick(data) {
    if (data) {
      const isExist = find(this.comment.linked_reports, function(o) { return o.id == data.post.id; });
      if (!isExist) {
        const obj = {
          id: data.post.id,
          name: data.post.title
        }
        this.comment.linked_reports.push(obj);
      }
    }
    this.openModal.next(false);
  }

  public hasUserPermission(permission: Permission) {
    return AuthHelpers.User.HasUserPermission(this.store, permission);
  }
}
