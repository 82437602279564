export interface FilterCriteria {
  management_level?: string,
  startDate?: Date,
  endDate?: Date,
  region?: string,
  woreda?: string,
  duration?: string,
  category?: string,
  format?: string,
  reportConfig?: any,
  title?: string;
  isDelete?: boolean;
  isReport?: boolean;
  isMapShow?: boolean;
  formId?: number;
}

export enum FormFields {
  DATE = 'Fecha y hora del incidente',
  INCIDENT_STATUS = 'Estado',
  STATUS = 'Estatus del incidente',
  REGION = 'Departmentos',
  ZONE = 'Municipios',
  PRIORITY = 'Prioridad',
  TYPE_OF_INCIDENT = 'Tipo de incidente',
  TITLE = 'Título'
} 

export interface ReportRecordType {
  fields: FormFields;  
}


