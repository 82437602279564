import { Component,
         OnDestroy,
         OnInit,
         Input, 
         Output, 
         EventEmitter         } from '@angular/core';
import { Store 							  } from '@ngrx/store';
import { Roles 							  } from '@eview/core/models/roles';
import { selectRoles 					} from '@eview/core/store/selectors/roles.selector';
import { AppState 						} from '@eview/core/store/states/app.state';
import { Permission 					} from '@eview/core/auth/permission';
import { FormGroup,
         FormBuilder,
         Validators						} from '@angular/forms';
import { AuthHelpers 					} from '@eview/core/auth';
import { AllowedPriviledge 		} from '@eview/core/auth/auth';
import { UsersHelpers 				} from '@eview/core/users/users.helpers';
import { filter               } from 'rxjs/operators';
import { Observable, 
         Subscription, 
         throwError	          } from 'rxjs';
import { catchError           } from 'rxjs/operators';
import { UserService          } from '@eview/core/users/user.service';
import { selectMap            } from '@eview/core/store/selectors/map.selector';
import { ContactType          } from '@eview/core/models/user';
import { PostSource           } from '@eview/core/domain/post/post';
import { isEmpty              } from 'lodash';
import { ToastService         } from '../../../toast/toast.service';
import { ToastType            } from '../../../toast/toast.component';

@Component({
  selector    : 'eview-user-password',
  templateUrl : 'user-password.component.html',
  styleUrls		: ['user-password.component.scss']
})

export class UserPasswordComponent implements OnInit, OnDestroy {
  @Input() userData       : any;
  @Input() editId         : any;
  @Input() isAnonymous    : boolean;
  @Input() isApproved     : boolean;
  @Output() onModalClose  : EventEmitter<Boolean> = new EventEmitter();
  
  AuthHelpers 		        = AuthHelpers;
  AllowedPriviledge       = AllowedPriviledge;
  Permission 			        = Permission;
  UsersHelpers 		        = UsersHelpers;

  subs				            : Subscription;
  userForm			          : FormGroup;
  
  constructor(
    private store			  : Store<AppState>,
    private userService : UserService,
    private formBuilder	: FormBuilder,
    private toast       : ToastService
  ) {
    this.subs 			= new Subscription();
  }

  ngOnInit() {
    this.createUserForm();
    this.onEditClick();
  }

  ngOnDestroy() {
    this.userForm.reset();
    this.subs.unsubscribe();
  }

  createUserForm() {
    this.userForm	 	=	this.formBuilder.group({							
      password	    : ['', [ Validators.required, Validators.minLength(7)]]
    });
  }

  onEditClick() {
    const user 	= this.userData;
    if (user) {
      this.userForm.patchValue({
        password  : user.password 
      });
    }
  }

  onSubmitClick() {
    let msgTemplate = 'TOAST_UPDATE_USER_PASSWORD';
    const reqBody   = this.userForm.value;

    if (reqBody && isEmpty(reqBody.password)) {
        delete reqBody.password;
    }
    this.subs.add(
      this.userService.updateUser(reqBody, this.editId)
                      .subscribe(
                        async response => {
                          this.onModalClose.next(false); 
                        })
      );
      this.toast.show(ToastType.Success, msgTemplate);
  }
}