import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild  
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@eview/core';
import { AuthHelpers } from '@eview/core/auth';
import { AllowedPriviledge } from '@eview/core/auth/auth';
import { Permission } from '@eview/core/auth/permission';
import {
  FormAttribute,
  FormAttributeInput,
  FormAttributes,
  FormAttributeType
} from '@eview/core/domain/form/form-attribute';
import { MediaService } from '@eview/core/domain/media/media.service';
import {
  Post,
  PostMediaType,
  PostManagementLevel,
  PostPriority,
  PostStatus,
  PostType
} from '@eview/core/domain/post/post';
import { PostCommentType } from '@eview/core/domain/post/post-comment';
import { PostHelpers } from '@eview/core/domain/post/post.helpers';
import { PostService } from '@eview/core/domain/post/post.service';
import { Tags } from '@eview/core/domain/tags/tag';
import { selectFormAttributes } from '@eview/core/store/selectors/form-attributes.selector';
import { selectTags } from '@eview/core/store/selectors/tags.selector';
import { AppState } from '@eview/core/store/states/app.state';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { FileSaverService } from 'ngx-filesaver';
import { combineLatest, Subscription, Observable, of } from 'rxjs';
import { map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { ECustomActions, ShowPostDetail } from '../../custom.store';
import { selectUsers } from '@eview/core/store/selectors/users.selector';
import { selectUser } from '@eview/core/store/selectors/user.selector';
import { User, Roles } from '@eview/core/models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PostEditorPageComponent } from '../../post-editor/post-editor/post-editor-page.component';
import { MapComponent } from '@eview/features/ui/map/map.component';
import { FormService } from '@eview/core/domain/form/form.service';

const EDIT_PAGE_URI = '/dashboard/post/edit/:id';
@Component({
  selector: 'eview-post-detail',
  templateUrl: 'post-detail.component.html',
  styleUrls: ['post-detail.component.scss']
})
export class PostDetailComponent implements OnInit, OnDestroy {
  @Input() type: string;
  constructor(
    protected store: Store<AppState>,
    private actions$: Actions,
    private mediaService: MediaService,
    private postService: PostService,
    private sanitizer: DomSanitizer,
    private fileSaverService: FileSaverService,
    private modalService: NgbModal,
    private formService: FormService
  ) {
    this.subs = new Subscription();
  }

  ngOnInit() {    
    this.subs.add(
      combineLatest(
        this.actions$.pipe(
          ofType<ShowPostDetail>(ECustomActions.ShowPostDetail)
        ),
        this.store.select(selectTags)
      ).subscribe(([showPostDetailAction, tags]) => {
        if (!showPostDetailAction.payload) {
          this._post = null;
          this.post = null;
          return;
        } else {
          this.subs.add(
            this.formService.listAllAttributes().subscribe((formAttributes) => {
            if (showPostDetailAction) {
              this._post = { ...showPostDetailAction.payload};
              this.post = this.formatPost(
                showPostDetailAction.payload,
                formAttributes,
                tags
              );    
              if (!this.isEditing) {
                this.postActivities$ = this.postService.getPostAudit(this.post.id).pipe(switchMap(result => of(result)));
                this.showComments = true;
              }
            }
            showPostDetailAction = null;            
          })
        );
        }
      })
    );    
    this.subs.add(
      this.users$.subscribe(users => (this.users = users))
    );
    this.subs.add(
      this.user$.subscribe(user => (this.user = user))
    );
  }

  ngOnDestroy() {
    this.users = null;
    this.post = null;
    this._post = null;
    if (this.childRef) {
      this.childRef.ngOnDestroy();
    }
    this.subs.unsubscribe();
  }

  _post: Post = null;
  post: PostEx = null;
  users: User[];
  user: User;
  users$: Observable<User[]> = this.store
    .select(selectUsers)
    .pipe(map(users => (users ? users.results : [])));

  user$: Observable<User> = this.store.select(selectUser);
  FormAttributeType = FormAttributeType;
  FormAttributeInput = FormAttributeInput;
  PostStatus = PostStatus;
  AllowedPriviledge = AllowedPriviledge;
  AuthHelpers = AuthHelpers;
  Permission = Permission;
  PostHelpers = PostHelpers;
  PostMediaType = PostMediaType;
  PostPriority = PostPriority;
  postActivities$: Observable<Audit>;
  format: string = environment.format.date;
  isEditing: boolean = false;
  showComments: boolean = false;
  @ViewChild(MapComponent, {static: false}) childRef: MapComponent;

  @Input() public hideLocationMap: boolean;
  @Output() public onShowLinkedPostClick: EventEmitter<number> = new EventEmitter();
  @Output() public onLinkedPostClick: EventEmitter<Object> = new EventEmitter();
  @Output() public onClose: EventEmitter<any> = new EventEmitter();
  @Output() public onPostUpdate: EventEmitter<any> = new EventEmitter();

  showDeleteConfirmation: boolean;
  private subs: Subscription;
  postStatuses = [
    PostStatus.Pending,
    PostStatus.InProgressVerification,
    PostStatus.Unverified,
    PostStatus.Verified,
    PostStatus.Responded,
    PostStatus.Evaluated
  ];
  postManagementLevels = [
    PostManagementLevel.Unknown,
    PostManagementLevel.Subregional,
    PostManagementLevel.Regional,
    PostManagementLevel.National
  ];
  PostCommentType = PostCommentType;
  mediaUrls: any = {};
  PostType = PostType;

  private formatPost(
    post: Post,
    formAttributes: FormAttributes,
    tags: Tags
  ): PostEx {
    const formatValues = (
      values: any,
      formAttributes: FormAttributes
    ): FormAttributeEx[] => {
      return Object.keys(values)
        .map(key => ({ key, value: values[key] }))
        .map(({ key, value }) => {
          if (!formAttributes) {
            return null;
          }
          const fa = {
            ...formAttributes.results.find(fa => fa.key === key),
            value
          };
          switch (fa.type) {
            case FormAttributeType.Tags:
              {
                fa.value = fa.value.map((v: any) =>
                  tags.results.find((t) =>  { 
                    if (isNaN(v)) {
                      return (t.tag === v);
                    } else {
                      return (t.id === parseInt(v));
                    }                    
                  })
                );
              }
              break;

            case FormAttributeType.Int:
            case FormAttributeType.Decimal:
              {
                fa.value = fa.value.map(v => (!isNaN(v)) ? parseFloat(v) : '');
              }
              break;

            case FormAttributeType.DateTime:
              {
                fa.value = fa.value.map(v => {
                  const time = moment
                    .utc(v)
                    .format(environment.format.time);
                  if (time === '00:00:00') {
                    return moment
                      .utc(v)
                      .format(environment.format.date);
                  }
                  return moment
                    .utc(v)
                    .format(environment.format.dateTimeWithoutSec);
                });
              }
              break;

            case FormAttributeType.Media:
              {
                fa.value.forEach(
                  v =>
                    this.mediaService
                      .read(parseInt(v))
                      .pipe(map(media => media))
                      .subscribe(media => {
                        let url = media.original_file_url;
                        let type = (media.mime.indexOf(PostMediaType.Video) === -1) ? PostMediaType.Photo : PostMediaType.Video;
                        this.mediaUrls[
                          fa.key
                        ] = {type: type, url: this.sanitizer.bypassSecurityTrustResourceUrl(url)};
                      }),
                  this
                );
              }
              break;
          }
          return fa;
        });
    };
    const orderValues = (values: FormAttributeEx[]): FormAttributeEx[] => {
      if (values[0] !== null) {
        return values.sort((fa0, fa1) =>
          fa0.priority === fa1.priority ? 0 : fa1.priority < fa0.priority ? 1 : -1
        );
      }
      return [];
    };
    return {
      ...post,
      values: orderValues(formatValues(post.values, formAttributes))
    };
  }

  private updatePost() {
    this._post.parent_id = this._post.parent ? this._post.parent.id : null;
    this.subs.add(
        this.postService.update(this._post).subscribe(post => {
        this._post = post;
        this.onPostUpdate.emit();
        this.store.dispatch({
          type: ECustomActions.UpdatedPost,
          payload: post
        });
        this.store.dispatch({
          type: ECustomActions.ShowPostDetail,
          payload: post
        });
      })
    );
  }

  onChangeStatusClick(status: PostStatus) {
    if (status === this._post.status) return;
    this._post.status = status;
    this.updatePost();
  }

  onChangePriorityClick() {
    this._post.priority =
      this._post.priority === PostPriority.Urgent
        ? PostPriority.Standard
        : PostPriority.Urgent;
    this.updatePost();
  }

  onRefreshClick() {
    this.postService.read(this.post.id).subscribe(post => {
      this.store.dispatch({
        type: ECustomActions.UpdatedPost,
        payload: post
      });
      this.store.dispatch({
        type: ECustomActions.ShowPostDetail,
        payload: post
      });
    });
  }

  onEditClick() {
    this.isEditing = true;
    const createIncidentModalRef = this.modalService.open(PostEditorPageComponent, { ariaLabelledBy: 'modal-basic-title', windowClass: "customModalWindowClass", size: "xl"});
    createIncidentModalRef.componentInstance.postId = this.post.id.toString();
    createIncidentModalRef.componentInstance.formId = this.post.form.id;
    createIncidentModalRef.componentInstance.type = this.type;
    this.hideLocationMap = true;
    this.subs.add(
        createIncidentModalRef.componentInstance.modalCloseEvent.subscribe(($e) => {
        if (this.type !== 'map') {
          this.hideLocationMap = false;
        }
        if (createIncidentModalRef.componentInstance) {
          createIncidentModalRef.componentInstance.ngOnDestroy();
          createIncidentModalRef.dismiss();
        }
        if (this.childRef) {
          this.childRef.ngOnDestroy();
        }
      })
    );
  }

  onDeleteClick(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true });
  }

  onDeleteConfirmationClick() {
    this.modalService.dismissAll('delete');
    this.postService.delete(this.post).subscribe((result) => {
      this.showDeleteConfirmation = false;
      this.store.dispatch({
        type: ECustomActions.DeletedPost,
        payload: this.post
      });
    }, ($error) => {
      console.log($error);
    });
  }

  isReportPriorityEnabled() {
    const allowedRoles: string[] = [Roles.SUPER_ROLE, Roles.ADMIN_ROLE];
    if (this.user && allowedRoles.indexOf(this.user.role) !== -1) {
      return true;
    }
    return false;
  }

  isReportStatusEnabled() {
    const allowedRoles: string[] = [Roles.SUPER_ROLE, Roles.ADMIN_ROLE, Roles.MGMT_ROLE, Roles.OPERATOR_ROLE];
    if (this.user && allowedRoles.indexOf(this.user.role) !== -1) {
      return true;
    }
    return false;
  }

  isResponseLevelAllowed() {
    const allowedRoles: string[] = [Roles.SUPER_ROLE, Roles.ADMIN_ROLE, Roles.MGMT_ROLE];
    if (this.user && allowedRoles.indexOf(this.user.role) !== -1) {
      return true;
    }
    return false;
  }

  onDownloadMediaClick(value: FormAttributeEx) {
    const url$ = this.mediaService
      .read(parseInt(value.value[0]))
      .pipe(
        map(media => media.original_file_url),
        switchMap(url =>
          this.mediaService
            .readContentAsBlob(url)
            .pipe(map(blob => ({ url, blob })))
        )
      )
      .subscribe(({ url, blob }) => {
        this.fileSaverService.save(blob, url.split('/').reverse()[0]);
      });
  }

  onShowLinkedPost(parentId: number) {
    this.onShowLinkedPostClick.emit(parentId);
  }

  onLinkToPostClick(post: Post, flag: boolean) {
    this.onLinkedPostClick.emit({ post: post, flag: flag });
  }

  onPostDetailClose() {
    this.onClose.emit();
  }

  getValueByLabel(postValue: any, label: string) {
    let fieldValue;
    fieldValue = (postValue) ? postValue.filter(fa => (fa.label && fa.label.toLocaleLowerCase() === label.toLocaleLowerCase())) : [];
    return (fieldValue && fieldValue[0] && fieldValue[0].value[0] || 0);
  };

  getUser(item, users) {
    const value = JSON.parse(item.value);
    return PostHelpers.FormatUser(value.updated_by, users);
  }

  getTypes(item) {
    if (item.type === 'create') {
      return 'ha creado';
    } else {
      return 'ha actualizado';
    }
  }

  getPostName(val) {
    const postDetail = JSON.parse(val);
    return (postDetail && postDetail.title !== '') ? '"' + postDetail.title +  '"' : '';
  }

  getContact(item, users) {
    const value = JSON.parse(item.value);
    let data = {};
    users.forEach(user => {
      if (user.id === value.updated_by) {
        data = user;
      }
    });
    if (data['contacts'] !== undefined && data['contacts'].length > 0) {
      return data['contacts'][0]['contact'];
    } else {
      return '';
    }
  }

  getStatus(postStatuses, type) {
    if (type === 'risk') {
      const values = ["published", "archived", "evaluated", "verification"];
      postStatuses = postStatuses.filter(item => !values.includes(item));
    }
    return postStatuses;
  }
}
interface FormAttributeEx extends FormAttribute {
  value: any;
}

interface PostEx extends Post {
  values: FormAttributeEx[];
}

interface Audit {
  created: string
  entity: string
  id: number
  type: string
  user_id: number
  value: string
}

