import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { RiskForecastComponent } from './components/risk-forecast.component';
import { RiskForecastMapComponent } from './components/risk-forecast-map.component';

export const routes: Routes = [
  {
    path: '',
    component: RiskForecastComponent,
    outlet: 'dashboard'
  }  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RiskForecastingRoutingModule {}
