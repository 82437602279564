import { Injectable } from '@angular/core';
import { BaseService } from '@eview/core/base/base-service';
import { Observable, of } from 'rxjs';
import { Risk, Risks } from './risk';

@Injectable({
  providedIn: 'root'
})

export class RiskDataService extends BaseService {


  readonly uris = {
    list: 'risk-forecasting/woreda-risk-data',
  };

  list(options: any): Observable<any> {
    
    // return of(risks)
    return this.http.get<Risks>(this.getUrl(this.uris.list), {
      params: super.getParamsFromObject(options)
    }); 
  }

  // read(id: number): Observable<Message> {
  //   return this.http.get<Message>(
  //     this.getUrl(this.uris.read).replace(':id', id.toString())
  //   );
  // }

  // update(post: Message): Observable<Message> {
  //   return this.http.put<Message>(
  //     this.getUrl(this.uris.update).replace(':id', post.id.toString()),
  //     post
  //   );
  // }  
}

const risks: Risks = {
  total_count: 15,
  count: 15,
  results: [{id:4436,forecastedRisk:1, woreda:"Dire Teyara"},
  {id:4371,forecastedRisk:1, woreda:"Siya Debirna Wayu"},
  {id:4413,forecastedRisk:2, woreda:"Abeshege"},
  {id:4286,forecastedRisk:1, woreda:"Mekhoni town"},
  {id:4186,forecastedRisk:2, woreda:"Malka Jabti /M.Jebdu)"},
  {id:4132,forecastedRisk:1, woreda:"Erebti"},
  {id:3967,forecastedRisk:2, woreda:"Shebe Dino"},
  {id:3786,forecastedRisk:1, woreda:"Enemay"},
  {id:3508,forecastedRisk:2, woreda:"Gemechis"},
  {id:3254,forecastedRisk:1, woreda:"Tena"},
  {id:3343,forecastedRisk:1, woreda:"Ababo"},
  {id:3352,forecastedRisk:1, woreda:"El-Ogaden"},
  {id:3361,forecastedRisk:1, woreda:"Meyumuluka"},
  {id:3142,forecastedRisk:1, woreda:"Merti"},
  {id:3165,forecastedRisk:1, woreda:"Wera Djo"},
  {id:3190,forecastedRisk:1, woreda:"Anchar"},
  {id:3227,forecastedRisk:1, woreda:"Tsegede (AM)"},
  {id:3239,forecastedRisk:1, woreda:"Harshin"},
  {id:3081,forecastedRisk:1, woreda:"Wenago"},
  {id:2948,forecastedRisk:1, woreda:"Gulele"},
  {id:2881,forecastedRisk:1, woreda:"Banja"},
  {id:2890,forecastedRisk:1, woreda:"Dedesa"},
  {id:2845,forecastedRisk:1, woreda:"Gerar Jarso"},
  {id:2853,forecastedRisk:2, woreda:"Gasera"},
  {id:2777,forecastedRisk:1, woreda:"Axum town"},
  {id:2784,forecastedRisk:1, woreda:"Meket"},
  {id:2815,forecastedRisk:1, woreda:"Afdera"},
  {id:2822,forecastedRisk:1, woreda:"Jimma Rare"},
  {id:2836,forecastedRisk:1, woreda:"Sofi"},
  {id:2759,forecastedRisk:1, woreda:"Hitosa"},
  {id:2647,forecastedRisk:1, woreda:"Debre Berhan town"},
  {id:2662,forecastedRisk:1, woreda:"Sekela"},
  {id:2619,forecastedRisk:2, woreda:"Aseko"},
  {id:2607,forecastedRisk:2, woreda:"Seden Sodo"},
  {id:2593,forecastedRisk:2, woreda:"Rama"},
  {id:2545,forecastedRisk:1, woreda:"Raya Azebo"},
  {id:2549,forecastedRisk:1, woreda:"Wayu Tuka"},
  {id:2561,forecastedRisk:1, woreda:"Dano"},
  {id:2458,forecastedRisk:1, woreda:"Burji Special"},
  {id:2431,forecastedRisk:3, woreda:"Gudetu Kondole"},
  {id:2348,forecastedRisk:1, woreda:"Were Ilu"},
  {id:2377,forecastedRisk:1, woreda:"Boricha"},
  {id:2321,forecastedRisk:1, woreda:"Kazira"},
  {id:2292,forecastedRisk:1, woreda:"Maji"},
  {id:2121,forecastedRisk:1, woreda:"Jimma Horo"},
  {id:2096,forecastedRisk:1, woreda:"Mile"},
  {id:2054,forecastedRisk:1, woreda:"Berehet"},
  {id:2064,forecastedRisk:2, woreda:"Addis Ketema (AA)"},
  {id:2013,forecastedRisk:2, woreda:"Bore"},
  {id:2015,forecastedRisk:3, woreda:"Abichugna Gne'a"},
  {id:1991,forecastedRisk:2, woreda:"Lideta"},
  {id:1975,forecastedRisk:1, woreda:"Lay Armacho"},
  {id:1942,forecastedRisk:1, woreda:"Bursa"},
  {id:1893,forecastedRisk:1, woreda:"Sekota"},
  {id:1876,forecastedRisk:1, woreda:"Guradamole"},
  {id:1853,forecastedRisk:3, woreda:"Diksis"},
  {id:1844,forecastedRisk:2, woreda:"Omo Nada"},
  {id:1830,forecastedRisk:2, woreda:"Mida Kegn"},
  {id:1802,forecastedRisk:1, woreda:"Metu Zuria"},
  {id:1808,forecastedRisk:1, woreda:"Wegera"},
  {id:1817,forecastedRisk:2, woreda:"Jinela"},
  {id:1795,forecastedRisk:1, woreda:"Limu (OR)"},
  {id:1772,forecastedRisk:1, woreda:"Pawe"},
  {id:1745,forecastedRisk:2, woreda:"Gurafereda"},
  {id:1752,forecastedRisk:2, woreda:"Kunneba"},
  {id:1753,forecastedRisk:2, woreda:"Lome (OR)"},
  {id:1720,forecastedRisk:2, woreda:"Gura Damole"},
  {id:1689,forecastedRisk:1, woreda:"Seru"},
  {id:1686,forecastedRisk:2, woreda:"Tach Armacho"},
  {id:1653,forecastedRisk:1, woreda:"Jarte Jardega"},
  {id:1583,forecastedRisk:1, woreda:"Garbo"},
  {id:1585,forecastedRisk:1, woreda:"Menze Gera Midir"},
  {id:1597,forecastedRisk:2, woreda:"Gota-Biki"},
  {id:1452,forecastedRisk:1, woreda:"Yaya Gulele"},
  {id:1471,forecastedRisk:1, woreda:"Mulo"},
  {id:1490,forecastedRisk:1, woreda:"Gursum (SM)"},
  {id:1408,forecastedRisk:1, woreda:"Damboya"},
  {id:1389,forecastedRisk:1, woreda:"Tole"},
  {id:1392,forecastedRisk:1, woreda:"Melka Balo"},
  {id:1354,forecastedRisk:2, woreda:"Siraro"},
  {id:1319,forecastedRisk:2, woreda:"Kimbibit"},
  {id:1288,forecastedRisk:1, woreda:"Dabat"},
  {id:1293,forecastedRisk:1, woreda:"East Imi"},
  {id:1296,forecastedRisk:1, woreda:"Dinsho"},
  {id:1286,forecastedRisk:1, woreda:"Jikawo"},
  {id:1287,forecastedRisk:1, woreda:"Chifra"},
  {id:1257,forecastedRisk:1, woreda:"Bila Seyo"},
  {id:1277,forecastedRisk:1, woreda:"Chora (Buno Bedele)"},
  {id:1194,forecastedRisk:1, woreda:"Munessa"},
  {id:1195,forecastedRisk:1, woreda:"Amigna"},
  {id:1200,forecastedRisk:1, woreda:"Teru"},
  {id:1203,forecastedRisk:1, woreda:"Mustahil"},
  {id:1234,forecastedRisk:1, woreda:"Dugda"},
  {id:1182,forecastedRisk:3, woreda:"Debark"},
  {id:1159,forecastedRisk:1, woreda:"Elidar"},
  {id:1169,forecastedRisk:1, woreda:"Wama Hagalo"},
  {id:1177,forecastedRisk:2, woreda:"Shaygosh"},
  {id:1134,forecastedRisk:1, woreda:"Durame town"},
  {id:1148,forecastedRisk:2, woreda:"Semada"},
  {id:1118,forecastedRisk:2, woreda:"Assagirt"},
  {id:1103,forecastedRisk:2, woreda:"Tenta"},
  {id:1104,forecastedRisk:1, woreda:"Mirab Armacho"},
  {id:1105,forecastedRisk:2, woreda:"Meda Welabu"},
  {id:1081,forecastedRisk:1, woreda:"Soro"},
  {id:1097,forecastedRisk:2, woreda:"Dasenech /Kuraz"},
  {id:1064,forecastedRisk:2, woreda:"Abobo"},
  {id:1071,forecastedRisk:2, woreda:"Nyngatom"},
  {id:1026,forecastedRisk:3, woreda:"Minjar Shenkora"},
  {id:1010,forecastedRisk:1, woreda:"Gezei Gofa"},
  {id:974,forecastedRisk:2, woreda:"Wadera"},
  {id:993,forecastedRisk:2, woreda:"Mekan Eyesuse"},
  {id:1000,forecastedRisk:2, woreda:"Hawzen"},
  {id:962,forecastedRisk:1, woreda:"Ebenat"},
  {id:965,forecastedRisk:2, woreda:"Jibat"},
  {id:966,forecastedRisk:1, woreda:"Berahile"},
  {id:970,forecastedRisk:1, woreda:"Gedeb"},
  {id:949,forecastedRisk:1, woreda:"Wenchi"},
  {id:953,forecastedRisk:1, woreda:"Kersa (East Hararge)"},
  {id:959,forecastedRisk:1, woreda:"Arba Minch Zuria"},
  {id:918,forecastedRisk:2, woreda:"Wuchale"},
  {id:935,forecastedRisk:1, woreda:"Babo"},
  {id:940,forecastedRisk:1, woreda:"Madira"},
  {id:882,forecastedRisk:1, woreda:"Janamora"},
  {id:883,forecastedRisk:1, woreda:"Golocha"},
  {id:895,forecastedRisk:2, woreda:"Gobu Seyo"},
  {id:914,forecastedRisk:2, woreda:"Debati"},
  {id:846,forecastedRisk:1, woreda:"Tach Gayint"},
  {id:848,forecastedRisk:1, woreda:"Fogera"},
  {id:852,forecastedRisk:1, woreda:"Jeldu"},
  {id:857,forecastedRisk:1, woreda:"Gimbichu"},
  {id:861,forecastedRisk:1, woreda:"Hintalo"},
  {id:868,forecastedRisk:1, woreda:"Bedele town"},
  {id:788,forecastedRisk:1, woreda:"Saba Boru"},
  {id:791,forecastedRisk:1, woreda:"Teltale"},
  {id:797,forecastedRisk:1, woreda:"Kokosa"},
  {id:803,forecastedRisk:1, woreda:"Thehulederie"},
  {id:816,forecastedRisk:1, woreda:"Habru"},
  {id:820,forecastedRisk:1, woreda:"Agarfa"},
  {id:822,forecastedRisk:1, woreda:"Becho (SW Shewa)"},
  {id:840,forecastedRisk:1, woreda:"Akaki - Kalit"},
  {id:786,forecastedRisk:2, woreda:"Moyale (SM)"},
  {id:751,forecastedRisk:1, woreda:"Sinana"},
  {id:733,forecastedRisk:1, woreda:"Kersana Malima"},
  {id:745,forecastedRisk:1, woreda:"Itang"},
  {id:715,forecastedRisk:1, woreda:"Basona Worena"},
  {id:717,forecastedRisk:2, woreda:"Gida Ayana"},
  {id:721,forecastedRisk:1, woreda:"Dembecha"},
  {id:724,forecastedRisk:1, woreda:"Debre Elias"},
  {id:708,forecastedRisk:2, woreda:"Miyo"},
  {id:710,forecastedRisk:1, woreda:"Shirka"},
  {id:714,forecastedRisk:1, woreda:"Sherkole"},
  {id:689,forecastedRisk:2, woreda:"Kombolcha"},
  {id:700,forecastedRisk:3, woreda:"Tikur Enchini"},
  {id:664,forecastedRisk:1, woreda:"Jarso (East Hararghe)"},
  {id:668,forecastedRisk:2, woreda:"Filtu"},
  {id:670,forecastedRisk:2, woreda:"Dera (AM)"},
  {id:671,forecastedRisk:1, woreda:"Libokemekem"},
  {id:628,forecastedRisk:1, woreda:"Ferfer"},
  {id:629,forecastedRisk:1, woreda:"Charati"},
  {id:633,forecastedRisk:2, woreda:"Nefas Silk"},
  {id:637,forecastedRisk:1, woreda:"Ambo town"},
  {id:643,forecastedRisk:1, woreda:"Yirgachefe"},
  {id:612,forecastedRisk:2, woreda:"Goba town"},
  {id:613,forecastedRisk:2, woreda:"Ilu"},
  {id:609,forecastedRisk:2, woreda:"Legehida"},
  {id:610,forecastedRisk:1, woreda:"Degehamedo"},
  {id:588,forecastedRisk:1, woreda:"Arero"},
  {id:594,forecastedRisk:1, woreda:"Goro Gutu"},
  {id:602,forecastedRisk:2, woreda:"Dalocha"},
  {id:603,forecastedRisk:2, woreda:"Wondo-Genet"},
  {id:587,forecastedRisk:1, woreda:"Debre Markos town"},
  {id:561,forecastedRisk:1, woreda:"Gunagado"},
  {id:572,forecastedRisk:3, woreda:"Aboker"},
  {id:533,forecastedRisk:1, woreda:"Asela town"},
  {id:536,forecastedRisk:1, woreda:"Dera (OR)"},
  {id:544,forecastedRisk:1, woreda:"Daro Lebu"},
  {id:552,forecastedRisk:2, woreda:"Abaala"},
  {id:559,forecastedRisk:1, woreda:"Ginir"},
  {id:515,forecastedRisk:1, woreda:"Lasta"},
  {id:521,forecastedRisk:1, woreda:"Cheliya"},
  {id:507,forecastedRisk:1, woreda:"Liben"},
  {id:511,forecastedRisk:1, woreda:"Amibara"},
  {id:491,forecastedRisk:1, woreda:"Limu Bilbilo"},
  {id:492,forecastedRisk:1, woreda:"Jeju"},
  {id:494,forecastedRisk:2, woreda:"Ameya"},
  {id:502,forecastedRisk:2, woreda:"Fentale"},
  {id:504,forecastedRisk:2, woreda:"Wonosho"},
  {id:480,forecastedRisk:1, woreda:"Kirkos"},
  {id:484,forecastedRisk:1, woreda:"Debub Achefer"},
  {id:439,forecastedRisk:1, woreda:"Adaba"},
  {id:445,forecastedRisk:1, woreda:"Midhaga Tola"},
  {id:448,forecastedRisk:1, woreda:"Kiremu"},
  {id:450,forecastedRisk:1, woreda:"Sibu Sire"},
  {id:466,forecastedRisk:1, woreda:"Erob"},
  {id:470,forecastedRisk:1, woreda:"Jarso (West Wellega)"},
  {id:425,forecastedRisk:2, woreda:"Habro"},
  {id:418,forecastedRisk:1, woreda:"Dubti"},
  {id:422,forecastedRisk:1, woreda:"Golo Oda"},
  {id:412,forecastedRisk:1, woreda:"Yeka"},
  {id:413,forecastedRisk:1, woreda:"Holeta town"},
  {id:415,forecastedRisk:1, woreda:"Abay Chomen"},
  {id:416,forecastedRisk:1, woreda:"Badele Zuria"},
  {id:389,forecastedRisk:1, woreda:"Alge Sachi"},
  {id:392,forecastedRisk:1, woreda:"Gawo Kebe"},
  {id:397,forecastedRisk:2, woreda:"Yubdo"},
  {id:360,forecastedRisk:2, woreda:"Borena /Debresina"},
  {id:363,forecastedRisk:2, woreda:"Hidabu Abote"},
  {id:377,forecastedRisk:1, woreda:"Ayira"},
  {id:379,forecastedRisk:2, woreda:"Nole Kaba"},
  {id:345,forecastedRisk:2, woreda:"West Imi"},
  {id:348,forecastedRisk:1, woreda:"Sebeta town"},
  {id:341,forecastedRisk:1, woreda:"Hamero"},
  {id:328,forecastedRisk:1, woreda:"Argoba"},
  {id:329,forecastedRisk:1, woreda:"Aware"},
  {id:330,forecastedRisk:1, woreda:"Hargele"},
  {id:331,forecastedRisk:1, woreda:"Dawo"},
  {id:333,forecastedRisk:1, woreda:"Meyu Muleke"},
  {id:338,forecastedRisk:1, woreda:"Sedi Chenka"},
  {id:340,forecastedRisk:1, woreda:"Gaji"},
  {id:302,forecastedRisk:2, woreda:"Kofele"},
  {id:304,forecastedRisk:1, woreda:"Adwa"},
  {id:310,forecastedRisk:2, woreda:"Deder"},
  {id:311,forecastedRisk:1, woreda:"Kurfa Chele"},
  {id:317,forecastedRisk:1, woreda:"Anfilo"},
  {id:323,forecastedRisk:2, woreda:"Gololcha Bale"},
  {id:291,forecastedRisk:1, woreda:"Saharti"},
  {id:274,forecastedRisk:1, woreda:"Degem"},
  {id:293,forecastedRisk:1, woreda:"Amuru"},
  {id:276,forecastedRisk:1, woreda:"Dangila"},
  {id:296,forecastedRisk:2, woreda:"Jinka town"},
  {id:283,forecastedRisk:1, woreda:"Sebeta Hawas"},
  {id:284,forecastedRisk:1, woreda:"Bure (OR)"},
  {id:289,forecastedRisk:2, woreda:"Gog"},
  {id:290,forecastedRisk:1, woreda:"Adama Tulu Jido Kombolcha"},
  {id:265,forecastedRisk:1, woreda:"Yirgalem town"},
  {id:233,forecastedRisk:1, woreda:"Arsi Negele"},
  {id:234,forecastedRisk:3, woreda:"Dodola"},
  {id:235,forecastedRisk:1, woreda:"Dessie Zuria"},
  {id:236,forecastedRisk:2, woreda:"Kacha Bira"},
  {id:237,forecastedRisk:2, woreda:"Barey"},
  {id:238,forecastedRisk:1, woreda:"Elkare /Serer"},
  {id:244,forecastedRisk:1, woreda:"Meta"},
  {id:253,forecastedRisk:1, woreda:"Goma"},
  {id:256,forecastedRisk:1, woreda:"Ejere /Addis Alem"},
  {id:261,forecastedRisk:1, woreda:"Guduru"},
  {id:262,forecastedRisk:1, woreda:"Lalo Asabi"},
  {id:264,forecastedRisk:2, woreda:"Meskan"},
  {id:225,forecastedRisk:2, woreda:"Ada'a"},
  {id:228,forecastedRisk:1, woreda:"West Belesa"},
  {id:230,forecastedRisk:2, woreda:"Dangura"},
  {id:231,forecastedRisk:1, woreda:"Isara"},
  {id:201,forecastedRisk:1, woreda:"Dama"},
  {id:203,forecastedRisk:2, woreda:"Adola"},
  {id:205,forecastedRisk:2, woreda:"Yabelo"},
  {id:208,forecastedRisk:1, woreda:"Degeluna Tijo"},
  {id:209,forecastedRisk:1, woreda:"Gedeb Asasa"},
  {id:210,forecastedRisk:2, woreda:"Kamashi"},
  {id:214,forecastedRisk:1, woreda:"Bedeno"},
  {id:215,forecastedRisk:1, woreda:"Shinile"},
  {id:218,forecastedRisk:2, woreda:"Doba"},
  {id:220,forecastedRisk:1, woreda:"Bole"},
  {id:222,forecastedRisk:1, woreda:"Mena (Jimma)"},
  {id:189,forecastedRisk:2, woreda:"Tahtay Koraro"},
  {id:191,forecastedRisk:1, woreda:"Hawi Gudina"},
  {id:192,forecastedRisk:2, woreda:"Farta"},
  {id:194,forecastedRisk:2, woreda:"Sabian"},
  {id:195,forecastedRisk:1, woreda:"Bure town"},
  {id:179,forecastedRisk:2, woreda:"Robe"},
  {id:181,forecastedRisk:2, woreda:"Wara Jarso"},
  {id:183,forecastedRisk:3, woreda:"Goro (Bale)"},
  {id:184,forecastedRisk:1, woreda:"Waliso"},
  {id:154,forecastedRisk:1, woreda:"Dihun"},
  {id:155,forecastedRisk:1, woreda:"Gode"},
  {id:156,forecastedRisk:1, woreda:"Woldiya town"},
  {id:158,forecastedRisk:1, woreda:"Quara"},
  {id:159,forecastedRisk:2, woreda:"Fedis"},
  {id:161,forecastedRisk:1, woreda:"Sasiga"},
  {id:162,forecastedRisk:1, woreda:"Jimma Arjo"},
  {id:165,forecastedRisk:1, woreda:"Sululta"},
  {id:167,forecastedRisk:1, woreda:"Bako Tibe"},
  {id:169,forecastedRisk:1, woreda:"Meta Robi"},
  {id:173,forecastedRisk:1, woreda:"Bonga town"},
  {id:175,forecastedRisk:1, woreda:"Hawasa town"},
  {id:148,forecastedRisk:1, woreda:"Wantawo"},
  {id:150,forecastedRisk:1, woreda:"Kuyu"},
  {id:151,forecastedRisk:1, woreda:"Kemisie town"},
  {id:127,forecastedRisk:1, woreda:"Lalo Kile"},
  {id:104,forecastedRisk:2, woreda:"Gashamo"},
  {id:130,forecastedRisk:1, woreda:"Adama"},
  {id:106,forecastedRisk:1, woreda:"Yeki"},
  {id:132,forecastedRisk:1, woreda:"Bahirdar Zuria"},
  {id:107,forecastedRisk:1, woreda:"Eferatana Gidem"},
  {id:133,forecastedRisk:1, woreda:"Abe Dongoro"},
  {id:108,forecastedRisk:1, woreda:"Kobo town"},
  {id:134,forecastedRisk:2, woreda:"Kelete Awelallo"},
  {id:110,forecastedRisk:1, woreda:"Alamata town"},
  {id:136,forecastedRisk:2, woreda:"Chilga 1"},
  {id:90,forecastedRisk:1, woreda:"Odo Shakiso"},
  {id:111,forecastedRisk:2, woreda:"Mena (Bale)"},
  {id:139,forecastedRisk:1, woreda:"Amaro"},
  {id:91,forecastedRisk:1, woreda:"Akobo"},
  {id:114,forecastedRisk:3, woreda:"Gursum (OR)"},
  {id:140,forecastedRisk:1, woreda:"Gimbi"},
  {id:92,forecastedRisk:1, woreda:"Karat town"},
  {id:116,forecastedRisk:1, woreda:"Miesso"},
  {id:117,forecastedRisk:1, woreda:"Tahtay Adiyabo"},
  {id:141,forecastedRisk:1, woreda:"Guliso"},
  {id:94,forecastedRisk:1, woreda:"Danod"},
  {id:118,forecastedRisk:1, woreda:"Boneya Boshe"},
  {id:142,forecastedRisk:2, woreda:"Mana Sibu"},
  {id:95,forecastedRisk:1, woreda:"Warder"},
  {id:119,forecastedRisk:1, woreda:"Mesela"},
  {id:143,forecastedRisk:1, woreda:"Gimbo"},
  {id:96,forecastedRisk:1, woreda:"Lude Hitosa"},
  {id:120,forecastedRisk:1, woreda:"Tulo (OR)"},
  {id:145,forecastedRisk:1, woreda:"Lege Hida"},
  {id:98,forecastedRisk:1, woreda:"Kore"},
  {id:123,forecastedRisk:2, woreda:"Welmera"},
  {id:146,forecastedRisk:1, woreda:"Aw-Bare"},
  {id:99,forecastedRisk:1, woreda:"Kalu"},
  {id:124,forecastedRisk:1, woreda:"Burayu"},
  {id:100,forecastedRisk:1, woreda:"Fiche town"},
  {id:125,forecastedRisk:1, woreda:"Welkait"},
  {id:101,forecastedRisk:1, woreda:"Gewane"},
  {id:126,forecastedRisk:1, woreda:"Metu town"},
  {id:103,forecastedRisk:1, woreda:"Bati"},
  {id:79,forecastedRisk:3, woreda:"Akaki"},
  {id:82,forecastedRisk:2, woreda:"Toke Kutaye"},
  {id:84,forecastedRisk:4, woreda:"Abuna Ginde Beret"},
  {id:85,forecastedRisk:1, woreda:"Gonder Zuria"},
  {id:88,forecastedRisk:1, woreda:"Seweyna"},
  {id:66,forecastedRisk:1, woreda:"Asayita"},
  {id:67,forecastedRisk:1, woreda:"Dire"},
  {id:71,forecastedRisk:1, woreda:"Danan"},
  {id:74,forecastedRisk:2, woreda:"Girawa"},
  {id:75,forecastedRisk:1, woreda:"Chinaksen"},
  {id:77,forecastedRisk:2, woreda:"Goba Koricha"},
  {id:50,forecastedRisk:1, woreda:"Arada"},
  {id:52,forecastedRisk:1, woreda:"Bereh"},
  {id:54,forecastedRisk:1, woreda:"Dolo Odo"},
  {id:55,forecastedRisk:1, woreda:"Kersa (Jimma)"},
  {id:56,forecastedRisk:1, woreda:"Sayo"},
  {id:57,forecastedRisk:1, woreda:"Dale Wabera"},
  {id:58,forecastedRisk:2, woreda:"Adda Berga"},
  {id:59,forecastedRisk:1, woreda:"Ambo Zuria"},
  {id:61,forecastedRisk:1, woreda:"Enderta"},
  {id:64,forecastedRisk:1, woreda:"Sodo"},
  {id:43,forecastedRisk:1, woreda:"Moyale (OR)"},
  {id:44,forecastedRisk:1, woreda:"Ziway Dugda"},
  {id:45,forecastedRisk:1, woreda:"Shala"},
  {id:46,forecastedRisk:1, woreda:"Bilidigilu"},
  {id:48,forecastedRisk:1, woreda:"Erer (SM)"},
  {id:49,forecastedRisk:2, woreda:"Guto Gida"},
  {id:17,forecastedRisk:1, woreda:"Haro Limu"},
  {id:33,forecastedRisk:1, woreda:"Shilabo"},
  {id:1,forecastedRisk:1, woreda:"Gora Dola"},
  {id:18,forecastedRisk:1, woreda:"Nunu Kumba"},
  {id:34,forecastedRisk:1, woreda:"Dila town"},
  {id:2,forecastedRisk:1, woreda:"Negele town"},
  {id:19,forecastedRisk:1, woreda:"Boke"},
  {id:35,forecastedRisk:1, woreda:"Nejo"},
  {id:3,forecastedRisk:1, woreda:"Shashemene town"},
  {id:4,forecastedRisk:1, woreda:"Worebabu"},
  {id:20,forecastedRisk:2, woreda:"Bedesa"},
  {id:36,forecastedRisk:1, woreda:"Begi"},
  {id:5,forecastedRisk:1, woreda:"Debre Libanos"},
  {id:21,forecastedRisk:1, woreda:"Mieso"},
  {id:37,forecastedRisk:1, woreda:"Fik"},
  {id:6,forecastedRisk:2, woreda:"Assosa"},
  {id:22,forecastedRisk:2, woreda:"Kolfe - Keran"},
  {id:38,forecastedRisk:1, woreda:"Welkite town"},
  {id:7,forecastedRisk:1, woreda:"Degehabur"},
  {id:23,forecastedRisk:1, woreda:"Kafta Humera"},
  {id:39,forecastedRisk:1, woreda:"Rayitu"},
  {id:8,forecastedRisk:1, woreda:"Bule Hora"},
  {id:24,forecastedRisk:1, woreda:"Gidami"},
  {id:40,forecastedRisk:1, woreda:"Kebribeyah"},
  {id:9,forecastedRisk:1, woreda:"Guangua"},
  {id:25,forecastedRisk:1, woreda:"Dendi"},
  {id:41,forecastedRisk:1, woreda:"Jigjiga town"},
  {id:10,forecastedRisk:1, woreda:"Guba Lafto"},
  {id:26,forecastedRisk:1, woreda:"Gambela Zuria"},
  {id:42,forecastedRisk:1, woreda:"Guba"},
  {id:11,forecastedRisk:1, woreda:"Debub Bench"},
  {id:27,forecastedRisk:3, woreda:"Boset"},
  {id:12,forecastedRisk:1, woreda:"Metema"},
  {id:28,forecastedRisk:1, woreda:"Finote Selam town"},
  {id:13,forecastedRisk:2, woreda:"Babile town"},
  {id:29,forecastedRisk:1, woreda:"Horo"},
  {id:14,forecastedRisk:2, woreda:"Haro Maya"},
  {id:30,forecastedRisk:1, woreda:"Gulo Mekeda"},
  {id:15,forecastedRisk:1, woreda:"Ayisha"},
  {id:31,forecastedRisk:1, woreda:"Ganta Afeshum"},
  {id:16,forecastedRisk:1, woreda:"Afdem"},
  {id:32,forecastedRisk:1, woreda:"Kebridehar"}]
}