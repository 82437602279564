import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent, environment } from '@eview/core';
import { AuthHelpers } from '@eview/core/auth';
import { Permission } from '@eview/core/auth/permission';
import { FeatureHelpers } from '@eview/core/domain/post/feature.helpers';
import {
  PostStatus,
  ListPostOptions,
  Post
} from '@eview/core/domain/post/post';
import { PostService } from '@eview/core/domain/post/post.service';
import {
  EMapActions,
  SetMapMarkers,
  UserClickedMap,
  UserClickedMarker
} from '@eview/core/store/actions/map.actions';
import { AppState } from '@eview/core/store/states/app.state';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription, BehaviorSubject, interval } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { ECustomActions, ShowPostDetail, DeletedPost } from '../../shared/custom.store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TagSelectorSetById } from '../../shared/post-editor/tag-selector/tag-selector.component';
import { ListForms } from '@eview/core/store/actions/forms.actions';
import { ListFormAttributes } from '@eview/core/store//actions/form-attributes.actions';
import { selectForms } from '@eview/core/store/selectors/forms.selector';
import { Form } from '@eview/core/domain/form/form';
import { Store } from '@ngrx/store';
import { PostType } from '@eview/core/domain/post/post';

const PAGE_SIZE = 20;

@Component({
  selector: 'eview-map-page',
  templateUrl: 'map-page.component.html',
  styleUrls: ['map-page.component.scss']
})
export class MapPageComponent extends BaseComponent
  implements OnInit, OnDestroy {
  constructor(
    store: Store<AppState>,
    private actions$: Actions,
    private router: Router,
    private postService: PostService,
    private modalService: NgbModal
  ) {
    super();
    this.subs = new Subscription();
    this.store = store
  }
  filterActive: boolean = false;
  tagId: number = 0;
  openModal = new BehaviorSubject(false);
  formId: number = environment.form.id;
  requestOptions: ListPostOptions = {
    status: PostStatus.All,
    has_location: 'mapped',
    parent: 'none',
    form: '1,2'
  };
  formList: Form[] = [];
  formIds: any = [];
  store: Store<AppState>;
  PostType = PostType;
  type: string = PostType.Incident;
  source = interval(environment.mapRefreshInMinutes * 60 * 1000);
  mapCheck: boolean = true;

  ngOnInit() {
    this.store.dispatch(new ListForms());
    this.subs.add(
      AuthHelpers.User.HasUserPermission(
      this.store,
      Permission.AccessEVIEWDashboard
    ).subscribe(can => {
      if(!can && localStorage.getItem('token') !== null) {
        this.router.navigate(['dashboard/posts']);
      }
    }));

    this.subs.add(
      this.store
      .select(selectForms)
      .pipe(filter(forms => forms !== null))
      .subscribe(
        forms => {
          this.formList = forms.results.filter(form => !form.disabled);
          this.formId = (this.formList.length) ? this.formList[0].id : environment.form.id;
          this.store.dispatch(new ListFormAttributes({ id: this.formId }));
          this.formIds = [];
          this.formList.forEach(element => {
            this.formIds.push(element.id)
          });
          this.requestOptions.form = this.formIds.join();
        }
      )
    );

    this.subs.add(
      this.actions$
        .pipe(ofType<DeletedPost>(ECustomActions.DeletedPost))
        .subscribe(action => {
          this.requestOptions.offset = 0;
          this.requestOptions.limit = PAGE_SIZE;
          this.onPostDeleted();
        })
    );

    this.subs.add(
      this.source.subscribe((val) => this.onRefreshClick())
    );

    document.body.style.alignItems = 'center';

    this.subs.add(
      AuthHelpers.User.HasUserPermission(
      this.store,
        Permission.CreateReport
      ).subscribe(can => {
        this.userCanSubmitPosts = can;
      })
    );

    // Permission.FilterPosts previous permissions
    this.subs.add(
      AuthHelpers.User.HasUserPermission(
        this.store,
        Permission.AccessIncidentRiskReports
      ).subscribe(can => {
        this.userCanFilterPosts = can;
      })
    );

    this.subs.add(
      this.router.events.subscribe(() => {
        document.body.style.alignItems = 'unset';
      })
    );

    this.subs.add(
      this.actions$
        .pipe(ofType<UserClickedMap>(EMapActions.UserClickedMap))
        .subscribe(action => {
          if (action.payload.actual && action.payload.actual.lat == null) {
            this.onRefreshClick();
          }
        })
    );

    this.subs.add(
      this.actions$
        .pipe(
          ofType<UserClickedMarker>(EMapActions.UserClickedMarker),
          switchMap(action => this.postService.read(action.payload.parent.id))
        )
        .subscribe(post => {
          this.showPostDetail = true;
          this.type = 'map';
          this.store.dispatch(new ShowPostDetail(post));
        })
    );
    this.loadMarkers();
  }

  ngOnDestroy() {
    this.mapCheck = false;
    this.formList = null;
    this.subs.unsubscribe();
  }

  mainPost: Post;

  tagName: string;

  selectedPost: Post;

  isLoadAllReport: boolean = false;

  applyDisabled: boolean = true;

  isErrorReport: boolean = false;

  loadAllParentReport: boolean = false;

  userCanSubmitPosts: boolean = false;

  userCanFilterPosts: boolean = false;

  showPostDetail: boolean = false;

  resetFilters = new BehaviorSubject(false);

  private subs: Subscription;

  AuthHelpers = AuthHelpers;
  
  Permission = Permission;

  loadMarkers(queryParams?: string[]) {

    if (queryParams) {
      for (const option in queryParams) {
        this.requestOptions[option] = queryParams[option];
      }
    }
    if (queryParams) {
      this.filterActive = true;
      this.requestOptions['parent'] = 'none';
    }
    this.subs.add(
      this.postService
      .geoJson(this.requestOptions).pipe(take(1))
      .subscribe(featureCollection => {
        this.store.dispatch(
          new SetMapMarkers({
            markers: FeatureHelpers.ToMarkers(featureCollection)
          })
        );
      })
    );
  }

  onRefreshClick(filterFlag?: boolean) {
    if (filterFlag) {
      this.filterActive = false;
      this.requestOptions = {
        status: PostStatus.All,
        has_location: 'mapped',
        parent: 'none',
        form: this.formIds
      }
    }
    this.loadMarkers();
  }

  onPostUpdate() {
    this.loadMarkers();
  }

  onPostDeleted() {
    this.onPostDetailClose();
    this.subs.add(
      this.postService
      .geoJson(this.requestOptions)
      .subscribe(featureCollection => {
        this.store.dispatch(
          new SetMapMarkers({
            markers: FeatureHelpers.ToMarkers(featureCollection)
          })
        );
      })
    );
  }

  onFiltersClick(content) {
    this.modalService.open(content, { centered: true, size: 'lg' });
    if (this.tagId > 0) {
      setTimeout(() => {
        this.store.dispatch(new TagSelectorSetById(this.tagId));
      });
    }
  }

  onPostDetailClose() {
    this.showPostDetail = false;
    this.store.dispatch(new ShowPostDetail(null));
  }

  onShowChildPosts(parentId: number) {
    if (parentId) {
      this.requestOptions['parent'] = parentId.toString();
      this.showPostDetail = false;
      this.loadMarkers();
    }
  }

  onLinkPostClicked(data) {
    this.selectedPost = data.post;
    this.openModal.next(data.flag);
    if (!data.flag) {
      this.onApplyClick(data.flag);
    }
  }

  onCancelClick() {
    this.openModal.next(false);
  }

  onApplyClick(dataObj) {
    if (dataObj.flag) {
      const { status, id, mgmt_lev, priority } = dataObj.post;
      this.selectedPost.parent_id = id;
      this.selectedPost.status = status;
      this.selectedPost.priority = priority;
      this.selectedPost.mgmt_lev = mgmt_lev;
    } else {
      this.selectedPost.parent_id = null;
    }

    this.subs.add(
      this.postService.update(this.selectedPost).subscribe(post => {
        this.store.dispatch({
          type: ECustomActions.UpdatedPost,
          payload: post
        });
        this.store.dispatch({
          type: ECustomActions.ShowPostDetail,
          payload: post
        });
      })
    );
    this.modalService.dismissAll();
  }

  onFormChange(selectedFormId: number) {
    this.formId = selectedFormId;
    this.requestOptions['form'] = selectedFormId;
    this.store.dispatch(new ListFormAttributes({ id: this.formId }));
    this.loadMarkers();
  }
}
