import { Component, OnDestroy, OnInit } from '@angular/core';
import RiskReportConfig from '../risk-foreact-report-format';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { RiskDataService } from '@eview/core/domain/risk-forecasting/risk-data.service';
import { Risk, RiskOptions } from '@eview/core/domain/risk-forecasting/risk';
import { environment } from '@eview/core/environments/environment';
import * as moment from 'moment';
@Component({
  selector: 'eview-risk-forecast',
  templateUrl: 'risk-forecast.component.html',
  styleUrls: ['risk-forecast.component.scss'],
})
export class RiskForecastComponent implements OnInit, OnDestroy {

  riskReports: {slice : any, dataSource: any, localization: any, options: any, formats: any} = RiskReportConfig;
  private subs: Subscription;
  public licenseKey: string = (environment.flexmonster)? environment.flexmonster.licenseKey : '';
  private reportData: any = [];
  private updatedReport: any;
  show: boolean = false;
  active: number = 1;

  constructor(
     private translate: TranslateService,
     private riskService: RiskDataService
  ) {
    this.subs = new Subscription();
    const currentLang = this.translate.currentLang;
    this.riskReports.localization = `/assets/i18n/flexmonster-localization-${currentLang}.json`
  }

  ngOnInit() {
    this.subs.add(
      this.translate.onLangChange.subscribe((langChangeEvent:LangChangeEvent) =>{
        this.riskReports.localization = `/assets/i18n/flexmonster-localization-${langChangeEvent.lang}.json`
      })  
    );
    // this.getAllReportsData();
  }

  // processedRiskData(data: Risk[]) {
  //   let temp = {} as any;
  //   for (let key of Object.keys(data[0])) {
  //     let obj;
  //     if (key === 'woreda') {
  //       obj = {"type": "string"};
  //     } else {
  //       obj = {"type": "number"};
  //     }
  //     temp[key] = obj;
  //   }
  //   data.unshift(temp);
  //   return data;
  // }

  // getAllReportsData() {
  //   this.reportData = []; let dateFormat = 'YYYY-MM-DD'; 
  //   let requestOptions: RiskOptions = {
  //     orderby: "date",
  //     startDate: moment(new Date()).subtract('15', 'days').format(dateFormat),
  //     endDate: moment(new Date()).format(dateFormat),
  //   };       
  //   this.riskService.list(requestOptions).subscribe(res => {
  //     if (res && res.count > 0) {
  //         this.riskReports.dataSource.data = this.processedRiskData(res.results);
  //         this.show = true;
  //       }
  //   });
  // }

  onReportReady(data: any) {
    if (data) {
      this.updatedReport = data;
      this.reportData.push(data);
    }
  }

  onCustomizeToolbar(toolbar) {
    let tabs = toolbar.getTabs();
    toolbar.getTabs = function() {
      const mapTab = [
          {
            id: 'fm-tab-maptab',
            title: 'Map',
            handler: 'onShowMap',
            icon:
              '<img width="36" height="36" style="border: 1px solid darkgrey;padding: 2px;margin-bottom: 7px;border-radius: 5px;" src="/assets/images/world.png" />'
          }          
      ];
      return tabs;
    };
  }

  ngOnDestroy() {

  }

}