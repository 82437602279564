import { Component, OnInit, AfterViewInit } from '@angular/core';
import { environment } from '@eview/core/environments/environment';
import { GraphBaseComponent } from '@eview/features/ui/post-editor/graph-base.component';
import RiskReportConfig from '../risk-foreact-report-format';
import { AppState } from '@eview/core/store/states/app.state';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { cloneDeep } from 'lodash';
import { Store } from '@ngrx/store';
import { Legends } from '@eview/core/domain/risk-forecasting/risk';
import { RiskDataService } from '@eview/core/domain/risk-forecasting/risk-data.service';
import { Risks, RiskOptions } from '@eview/core/domain/risk-forecasting/risk';
import { TranslateService } from '@ngx-translate/core';
import * as L from 'leaflet';

@Component({
  selector: 'eview-risk-forecast-map',
  templateUrl: 'risk-forecast-map.component.html',
  styleUrls: ['risk-forecast-map.component.scss'],
})
export class RiskForecastMapComponent extends GraphBaseComponent implements OnInit, AfterViewInit {

  private riskReports: {slice : any, dataSource: any, localization: any, options: any, formats: any} = RiskReportConfig;
  public licenseKey: string = (environment.flexmonster)? environment.flexmonster.licenseKey : '';
  private mapDataset = {
    woredas: []
  };
  private firstLoadFlag: boolean = true;
  private riskMapData: Risks;
  private layerConfig;
  protected requestOptions: RiskOptions = {orderby: 'date', type: 0};

  constructor(store: Store<AppState>, spinner: SpinnerVisibilityService, private riskService: RiskDataService, private translate: TranslateService) {
    super(store, spinner);
  }

  ngOnInit() {
    this.mapId = 'risk-map';
    this.layer = null;
    this.getForecastedValuesForWoreda();
  }


  getForecastedValuesForWoreda() {
    this.riskService.list(this.requestOptions).subscribe(res => {
      if (res && res.count > 0) {
          this.riskMapData = cloneDeep(res);
          this.getFormattedDataMap();
      } else {
        this.mapDataset['woredas'] = [];
        this.addToolTipsToRegions();
      }
    });
  }

  getFormattedDataMap() {
    let woredaList = [];
    if (this.riskMapData && this.riskMapData.count > 0) {
      const dataSet = this.riskMapData.results;
      for (let info of dataSet) {
        woredaList[info.woreda] = info.forecastedRisk;
      }
    }
    this.mapDataset['woredas'] = woredaList;
    this.addToolTipsToRegions();
  }

  addLegends() {
    let legend = L.control.attribution({ position: 'bottomright' });
      legend.onAdd = map => {
        let div = L.DomUtil.create('div', 'info legend');          
        div.style.display = "flex";
        div.style.flexDirection = "column";
        // loop through our density intervals and generate a label with a colored square for each interval
        for (let i = 5; i > 0; i--) {
          div.innerHTML +=
            '<div style="margin: 5px"><i class="custom-legend" style="background:' +
            this.getColor(i) + '"></i> ' +
            Legends[i-1][i] + '<br>';
        }
        div.innerHTML += '</div>';
        div.style.lineHeight = '18px';
        div.style.color = '#555';
        return div;
      };
      legend.addTo(this.map);
  }

  getRiskData(val: number) {
    this.spinner.show();
    if (this.requestOptions) {
      this.requestOptions['type'] = val;
      this.getForecastedValuesForWoreda();
    }
  }

  addCurrentExtendedOptionLayer() {
    let temp;
    const riskLayerOptions = environment.riskMapOptions;
    let riskLayer = L.control.attribution({ position: 'topright' });
      riskLayer.onAdd = map => {
        let div = L.DomUtil.create('div', 'Risk Filter');
        temp = '<select class="custom-class" style="cursor:pointer;" id="risk-select">';
          for(let i = 0; i < riskLayerOptions.length; i++) {
              const label = this.translate.instant(riskLayerOptions[i].name);
              if (riskLayerOptions[i].isDefault) {
                temp += '<option selected value="' + riskLayerOptions[i].value +'">' + label + '</option>';
              } else {
                temp += '<option value="' + riskLayerOptions[i].value +'">' + label + '</option>';
              }
          };
        div.innerHTML = temp + '</select>';    
        div.firstElementChild.addEventListener('change', () => {
          const selVal = div.firstElementChild['value'];
          this.getRiskData(selVal);

        });
        return div;
      };
      
      riskLayer.addTo(this.map);
  }

  getColor(d) {
    return d == 5
      ? '#bd0026'
      : d == 4
      ? '#f03b20'
      : d == 3
      ? '#fd8d3c'
      : d == 2
      ? '#fecc5c'
      : d == 1
      ? '#ffffb2'
      : '#ffffff';
  }

  getRegionStyle(feature) {
    if (this.layerConfig && this.layerConfig.altName === 'Province') {
      return null;
    } else {
      return {
        fillColor: this.getColor(feature.properties.count),
        weight: (this.layerConfig) ? this.layerConfig.weight : 4,
        opacity: (this.layerConfig) ? this.layerConfig.opacity : 1,
        color: (this.layerConfig) ? this.layerConfig.color : 'grey',
        dashArray: (this.layerConfig) ? this.layerConfig.dashArray : '1',
        fillOpacity: 0.7,
        innerHTML: feature.properties.name
      };
    }
  }

  onEachFeature() {
    return null;
  }

  highlightFeature(e) {
    let layer = e.target;
    layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  addToolTipsToRegions() {
    let layerData = [];
    let layerArr = [];
    this.layersMetaData.map((d1, index) => {
        const features = cloneDeep(d1.features);
        layerData = features.map((item) => {
            let areaName, areaList;
            if (index === 1) {
              areaName = item.properties.ADM2_EN;
            } else if (index === 2) {
              areaName = item.properties.ADM3_EN;
              areaList = this.mapDataset.woredas;
            } else if (index === 0) {
              areaName = item.properties.ADM1_EN;
            }
            if (areaList !== undefined) {
              item.properties.name = areaName;
              item.properties.count = areaList[areaName];
            }        
          return item;
        });

        const config = this.configs.find(c => c.fileName === d1.fileName) || {
                  base: false,
                  selected: false,
                  options: null,
                  listenForClick: null
                };
        this.layerConfig = config.options.style;

        this.layer = L.geoJSON(layerData as any, {
          style: this.getRegionStyle.bind(this),
          onEachFeature: this.onEachFeature.bind(this)
        })
        .bindTooltip((layer: any) => {
          let tooltip = '';
          if (index === 0) {
            tooltip = layer.feature.properties.ADM1_EN;
          } else if (index === 1) {
            tooltip = layer.feature.properties.ADM2_EN;
          } else {
            tooltip = layer.feature.properties.ADM3_EN;
          }
          const riskLevel = (layer.feature.properties && layer.feature.properties.count) ? layer.feature.properties.count : 1;
          tooltip = tooltip + ' (' + Legends[riskLevel - 1][riskLevel] + ')';
          return tooltip;
        });
      
      layerArr.push(this.layer);
      this.mapLayerControl.remove();
      this.map.createPane('labels');
      this.map.getPane('labels').style.zIndex = '650';
      this.map.getPane('labels').style.pointerEvents = 'none';
      L.tileLayer('https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png', {
        attribution: '©OpenStreetMap, ©CartoDB',
        pane: 'labels'
      }).addTo(this.map);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(this.map);
    });
    this.spinner.hide();
    if (layerArr && layerArr.length > 0) {
      layerArr[1].addTo(this.map);
      layerArr[0].addTo(this.map);
      layerArr[2].addTo(this.map);
    }    
  }

  onAddLayers(index: number, config: any, d1: any) {
   if (this.firstLoadFlag && index >= 0) {
      this.mapLayerControl.addBaseLayer(
        this.layer,
        config.altName || d1.fileName
      );
   }
    if (index === 2) {
      this.firstLoadFlag = false;
    }
  }

  ngAfterViewInit() {
    this.spinner.show();
    setTimeout(() => {
      this.buildMap();
      this.addLegends();
      this.addCurrentExtendedOptionLayer();
      this.addShapefiles();
    }, 500);

  }

}