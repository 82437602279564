import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  EventEmitter,
  AfterViewInit
} from '@angular/core';
import { AppState } from '@eview/core/store/states/app.state';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FormAttributes, FormAttribute } from '@eview/core/domain/form/form-attribute';
import { PostHelpers } from '@eview/core/domain/post/post.helpers';
import { FilterHelpers } from '@eview/core/domain/filters/filters.helper';
import {
  FilterItem,
  PostFilters
} from '@eview/core/domain/post/post';
import { User, ListUserOptions } from '@eview/core/models/user';
import { UserService } from '@eview/core/users/user.service';
import { map } from 'rxjs/operators';
import { selectTags } from '@eview/core/store/selectors/tags.selector';
import { selectMap } from '@eview/core/store/selectors/map.selector';
import { selectFormAttributes } from '@eview/core/store/selectors/form-attributes.selector';
import { Tag, Tags } from '@eview/core/domain/tags/tag';
import {
  TagWithChildren,
  TagHelpers
} from '@eview/core/domain/tags/tag.helpers';
import {
  ETagSelectorActions,
  TagSelectorChange,
  TagSelectorSetById
} from '../post-editor/tag-selector/tag-selector.component';
import { FilterService } from './filters.service';
import { isEmpty, indexOf } from 'lodash';
import { OrderSort } from '@eview/core/models/commons';
import {
  debounceTime,
  filter,
  tap,
  switchMap,
  catchError
} from 'rxjs/operators';
import { Observable, Subscription, of } from 'rxjs';
import { NgbDate, NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';

const PAGE_SIZE = 20;
const RISK_FORM_ID = 2;
@Component({
  selector: 'eview-filters',
  templateUrl: 'filters.component.html',
  styleUrls: ['filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isLoadAllReport: boolean;
  @Input() formID: number;
  @Output() onApply: EventEmitter<Object> = new EventEmitter();
  @Output() onCancel: EventEmitter<Boolean> = new EventEmitter();

  subs: Subscription;
  model: string;
  
  applyDisabled: boolean = true;
  selectedTags: any = [];
  formAttributesFilters: FormAttribute[];
  PostHelpers = PostHelpers;
  selectedPostFilters: PostFilters[] = [];
  selectedFormFilters: any[] = [];
  listOption: ListUserOptions;
  FilterHelpers = FilterHelpers;
  users: User[];  
  limit: number = PAGE_SIZE;
  public searchFailed: boolean = false;
  public isSearchingByAddress: boolean = false;
  public total_count: number = 0;
  public formatter = (result: any) => result.realname;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.applyDisabled = (!this.fromDate && !this.toDate);
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatterDate.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }


  constructor(private store: Store<AppState>, 
              private actions$: Actions,
              private filterService: FilterService,
              private userService: UserService,
              private calendar: NgbCalendar, 
              public formatterDate: NgbDateParserFormatter
            ) {
    this.subs = new Subscription();
  }

  tags$: Observable<Tags> = this.store.select(selectTags);

  formAttributes: FormAttributes;

  mapRegions:any = [];

  filters: FilterItem[] = [];

  PostFilters = PostFilters;

  selectedRegion:any = [];

  availableTags$: Observable<Tag[]> = this.tags$.pipe(
    map(tags => tags.results as Tag[])
  );

  nestedTags$: Observable<TagWithChildren[]> = this.tags$.pipe(
      map(tags =>
        tags && tags && tags.results ? TagHelpers.ProcessForRendering(tags, {form_stage_id: this.formID, input: PostFilters.Categories, value: [this.selectedTags['category']]}) : null
      )
    );

  ngAfterViewInit() {
    if (this.selectedTags) {
      setTimeout(() => {
        const keys = Object.keys(this.selectedTags);
        for (let i = 0; i < keys.length; i++) {
          this.store.dispatch(new TagSelectorSetById(this.selectedTags[i]));
        }
      });
    }
  }

  ngOnInit() {
    this.subs.add(
      this.store
        .select(selectFormAttributes)
        .subscribe((formAttributes) => {
          this.formAttributes = formAttributes;
          if (this.formAttributes.results.length > 0) {
              this.formAttributesFilters = this.formAttributes.results.filter((item) => item.type === 'varchar' && (item.input === 'radio' || item.input === 'select'));
              this.formAttributesFilters.map((item) => {
                  this.selectedFormFilters[item.key] = -1;
              });              
            }
        })
    );

    this.subs.add(this.store.select(selectMap).subscribe(map => {
      if (map) {
        this.mapRegions = map.regions;
        this.filters = FilterHelpers.GetFilters(this.mapRegions);
        this.filters.map((item) => {
          this.selectedPostFilters[item.queryParam] = -1;
        });
       }
    })
    );

    const prefetchFilters = this.filterService.filterOptions;
    if (!isEmpty(prefetchFilters)) {
      this.selectedPostFilters = prefetchFilters.postFilters;
      this.selectedFormFilters = prefetchFilters.formFilters;
      if (prefetchFilters.tags && prefetchFilters.tags) {
        this.selectedTags = prefetchFilters.tags;
      }
      if (this.selectedPostFilters[PostFilters.Province]) {
        this.onChange(PostFilters.Province);
      }
    }

    this.subs.add(
      this.actions$
        .pipe(ofType<TagSelectorChange>(ETagSelectorActions.Change))
        .subscribe(action => {
          if (action.payload) {
            this.selectedTags[action.payload.type] = action.payload.id;
            this.applyDisabled = false;
          }
        })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.selectedTags = {};
  }

  setFilterValues(keyToBeSet: string, values: any[]) {
    this.filters.map((item) => {
        if (item.queryParam === keyToBeSet) {
          item.values = values;
        }
        return item;
      });
    
    if (this.formAttributesFilters) {
      this.formAttributesFilters.map((item) => {
        let temp = [];
        values.map((item) => {
            temp.push(item.value);
        });
        if (item.key === keyToBeSet) {
          item['options'] = cloneDeep(temp);
        }
        return item;
      });
    }
  }

  isFiltersSelected() {
    this.applyDisabled = true;
    for(let option in this.selectedPostFilters) {
      if (parseInt(this.selectedPostFilters[option]) !== -1) {
        this.applyDisabled = false;
      }
    };
    for(let option in this.selectedFormFilters) {
      if (parseInt(this.selectedFormFilters[option]) !== -1) {
        this.applyDisabled = false;
      }
    };
  }

  onChange(optionSelected: string) {
    if (optionSelected === PostFilters.Province || optionSelected === 'region_default') {
      this.selectedRegion = (optionSelected === 'region_default') ? this.selectedFormFilters['region_default'] : this.selectedPostFilters[PostFilters.Province];
      optionSelected = (optionSelected === PostFilters.Province) ? PostFilters.District : 'zone_default';
      if (this.selectedRegion !== '-1') {
        let temp = [], districts = [];
        if (!isEmpty(this.mapRegions[this.selectedRegion])) {
            this.mapRegions[this.selectedRegion].forEach(element => {
            if (indexOf(temp, element.municipios) === -1) {
              temp.push(element.municipios);
              districts.push({name: element.municipios, value: element.municipios});   
            }
          });
          this.setFilterValues(optionSelected, districts);
        }
      }
    }
    this.isFiltersSelected();   
  }

  onApplyClick() {
    let collection = [];
    this.filterService.filterOptions = {
      postFilters: this.selectedPostFilters,
      formFilters: this.selectedFormFilters,
      tags: this.selectedTags
    };
    if (this.selectedPostFilters) {
      for(const key in this.selectedPostFilters) {
        let val = this.selectedPostFilters[key].toString();
        if (val !== '-1')
          collection[key] = this.selectedPostFilters[key];
      }
    }
    if (this.selectedFormFilters) {
      for(const key in this.selectedFormFilters) {
        let val = this.selectedFormFilters[key].toString();
        if (val !== '-1')
          collection['values[' + key + ']'] = this.selectedFormFilters[key];
      }
    }
    if (this.selectedTags && Object.keys(this.selectedTags).length > 0) {
      collection['tags'] = Object.values(this.selectedTags);
    }
    if (this.users && this.users.length > 0) {
      collection['user'] = this.users[0].id;
    }
    if (collection[PostFilters.Incident_Status]) {
      collection['status'] = collection[PostFilters.Incident_Status];
      delete collection[PostFilters.Incident_Status];
    }
    if (this.fromDate && this.toDate) {
      collection['created_after'] = FilterHelpers.GetDateFromObject(this.fromDate);
      collection['created_before'] = FilterHelpers.GetDateFromObject(this.toDate);
    }
    this.onApply.emit(collection);
    this.applyDisabled = true;
  }

  onValueSelected(result) {
    const selectedUser = result && result.item ? result.item : [];
    this.users = this.users.filter(user => (user.realname === selectedUser.realname));
    this.applyDisabled = this.users.length === 0 || false;
  }

  resetFilterValues() {
    this.selectedFormFilters = [];
    this.selectedPostFilters = [];
    this.filterService.filterOptions = [];
  }

  onCancelClick() {
    this.resetFilterValues();
    this.applyDisabled = true;
    this.onCancel.emit(false);
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      tap(() => {
        this.searchFailed = false;
        this.isSearchingByAddress = true;
      }),
      filter(term => {
        if (term.length >= 2) {
          return true;
        } else {
          this.isSearchingByAddress = false;
          return this.isSearchingByAddress;
        }
      }),
      switchMap(address => {
        this.listOption = {
          offset: 0,
          limit: this.limit,
          orderby: 'realname',
          order: OrderSort.Asc,
          q: address
        };

        return this.userService.list(this.listOption).pipe(
          map(data => {
            if (data) {
              this.searchFailed = false;
              this.total_count = data.total_count;
              return data.results.map(res => res);
            } else {
              this.searchFailed = address === '' ? false : true;
              return [];
            }
          }),
          catchError(err => {
            this.searchFailed = true;
            this.isSearchingByAddress = false;
            return of(null);
          })
        )
      }),
      tap((data) => {
        this.users = data;
        this.isSearchingByAddress = false;
        return this.isSearchingByAddress;
      })
    );
}
