import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexmonsterPivotModule } from 'ng-flexmonster';
import { RiskForecastingRoutingModule } from './risk-forecasting-routing.module';
import { RiskForecastComponent } from './components/risk-forecast.component';
import { RiskForecastMapComponent } from './components/risk-forecast-map.component';

@NgModule({
  imports: [
    SharedModule,
    NgbModule,
    FlexmonsterPivotModule,
    FontAwesomeModule,
    RiskForecastingRoutingModule
  ],
  exports: [RouterModule],
  declarations: [RiskForecastMapComponent, RiskForecastComponent]
})
export class RiskForecastingModule {}
