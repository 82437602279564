import { OnInit}  from '@angular/core';
import { environment } from '@eview/core';
import { GeoJsonObject } from '@eview/core/domain/post/geo-json';
import { selectMap } from '@eview/core/store/selectors/map.selector';
import { Store } from '@ngrx/store';
import { AppState } from '@eview/core/store/states/app.state';
import * as L from 'leaflet';
import * as shp from 'shpjs';
import { SpinnerVisibilityService } from 'ng-http-loader';
const MAP_ID: string = 'risk-map';
const MAP_INITIAL_CENTER: L.LatLngExpression = [0, 0];
const MAP_INITIAL_ZOOM: number = 7;
const MAP_ZOOM_POSITION: L.ControlPosition = 'bottomleft';
const MAP_LAYER_POSITION: L.ControlPosition = 'bottomleft';
const MAP_URL_TEMPLATE: string =
  '';
const MAP_ZOOM_MAX: number = 8;
const MAP_ATTRIBUTION: string =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>';

export abstract class GraphBaseComponent implements OnInit {
  constructor(protected store: Store<AppState>, protected spinner: SpinnerVisibilityService) {}

  mapId: string = MAP_ID;
  protected map: L.Map;
  protected mapLayerControl: L.Control.Layers;
  protected layer: any;
  protected layersMetaData: any = [];

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  showDataLabel: boolean = true;
  configs: any = [];

  ngOnInit() {
  }

  addShapefiles() {
    const queue: Promise<any>[] = environment.map.shapeFiles.map(
      sf => shp(sf.uri) as Promise<any>
    );
    this.configs = environment.map.shapeFiles.reduce(
      (cfgs, sh) => [...cfgs, ...sh.config],
      []
    );
    Promise.all(queue).then(data => {
      data.forEach(d =>
        d.forEach((d1: GeoJsonObject, index) => {
              // if (index > 0 && index < 4) {
                this.layersMetaData.push(d1);
              //}
        })
      );
      this.spinner.hide();
      this.addToolTipsToRegions();
    });
  }

  abstract addToolTipsToRegions();

  highlightFeature(e) {
    let layer = e.target;
    layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  resetHighlight(e) {
    this.layer.resetStyle(e.target);
  }

  zoomToFeature(e) {
    this.map.fitBounds(e.target.getBounds());
  }

  onEachFeature(feature, layer) {
    layer.on({
      mouseover: this.highlightFeature.bind(this),
      mouseout: this.resetHighlight.bind(this),
      click: this.zoomToFeature.bind(this)
    });
  }

  buildMap() {
      this.map = L.map(this.mapId, {
        center: MAP_INITIAL_CENTER,
        zoom: MAP_INITIAL_ZOOM,
        zoomControl: false,
        scrollWheelZoom: false
      });

      L.control
        .zoom({
          position: MAP_ZOOM_POSITION
        })
        .addTo(this.map);
      const tiles = L.tileLayer(MAP_URL_TEMPLATE, {
        maxZoom: MAP_ZOOM_MAX,
        attribution: MAP_ATTRIBUTION
      });
      tiles.addTo(this.map);      
      this.mapLayerControl = L.control
      .layers(null, null, { position: MAP_LAYER_POSITION })
      .addTo(this.map);

      this.store.select(selectMap).subscribe(map => {
        if (!map || !this.map) return;
        this.map.setView([map.defaults.lat, map.defaults.lon], MAP_INITIAL_ZOOM);
      });
    }
}
