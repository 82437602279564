import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivationStart, Router, RouterOutlet, NavigationEnd } from '@angular/router';
import { BaseComponent, environment } from '@eview/core';
import { AuthHelpers } from '@eview/core/auth';
import { Permission } from '@eview/core/auth/permission';
import { ConfigHelpers } from '@eview/core/config/config.helpers';
import { SiteConfigItem } from '@eview/core/models/config';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@eview/core/models/user';
import { UserPermissions } from '@eview/core/models/user-permissions';
import { Logout } from '@eview/core/store/actions/auth.actions';
import { EConfigActions } from '@eview/core/store/actions/config.actions';
import { selectConfig } from '@eview/core/store/selectors/config.selector';
import { selectUserPermissions } from '@eview/core/store/selectors/user-permissions.selector';
import { selectUser } from '@eview/core/store/selectors/user.selector';
import { AppState } from '@eview/core/store/states/app.state';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectUnreadNotifications } from '@eview/core/store/selectors/notifications.selector';
import { selectNetworkStatus } from '@eview/core/store/selectors/network-status.selector';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PostEditorPageComponent } from '../shared/post-editor/post-editor/post-editor-page.component';
import { selectForms } from '@eview/core/store/selectors/forms.selector';
import { Form } from '@eview/core/domain/form/form';
import { PostType } from '@eview/core/domain/post/post';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Component({
  selector: 'eview-index',
  templateUrl: 'index.component.html',
  styleUrls: ['index.component.scss']
})
export class IndexComponent extends BaseComponent implements OnInit, OnDestroy {

  unreadNotifications$: Observable<boolean>
  isNetworkAvailable$: Observable<any>
  userPermissions$: Observable<UserPermissions>
  user$: Observable<User>
  footer$: Observable<string>
  deploymentName$: Observable<string>
  siteConfig$: Observable<SiteConfigItem>
  store: Store<AppState>
  time = { hour: 'Hrs', minute: 'Mins' };
  countryCodes = environment.countryCodes;
  currentLang: string = this.translate.currentLang;
  setMarginMap: boolean = true;

  AuthHelpers = AuthHelpers;

  Permission = Permission;
  formList: Form[];
  PostType = PostType;

  pageInfo:any = { logo : { header: false, sidebar: true }};
  
  constructor(
    store: Store<AppState>,
    private actions$: Actions,
    private router: Router,
    private translate: TranslateService,
    private modalService: NgbModal,
    private spinner: SpinnerVisibilityService
  ) {
    super();
    this.store = store
    this.subs = new Subscription();
    this.siteConfig$ = this.store
      .select(selectConfig)
      .pipe(
        map(config =>
          ConfigHelpers.GetConfigItem<SiteConfigItem>(SiteConfigItem, config)
        )
      );

    this.deploymentName$ = this.siteConfig$.pipe(
      map(siteConfig =>
        siteConfig ? siteConfig.name : environment.deploymentName
      )
    );
        
    this.footer$ = this.siteConfig$.pipe(
      map(siteConfig =>
        siteConfig ? siteConfig.description : environment.footer.text
      )
    );
    this.user$ = this.store.select(selectUser);
    this.userPermissions$ = this.store.select(
      selectUserPermissions
    );
    this.isNetworkAvailable$ = this.store.select(
      selectNetworkStatus
    );
    this.unreadNotifications$ = this.store.select(
      selectUnreadNotifications
    );
  
  }
  

  ngOnInit() {
    this.subs.add(
      this.router.events.subscribe(e => {

        if (e instanceof NavigationEnd) {
          if (e && e.url === '/dashboard/posts') {
            this.setMarginMap = false;
          } else {
            this.setMarginMap = true;
          }
        }
        
        if (e instanceof ActivationStart) {
          window.scroll(0, 0);
          if (e.snapshot.outlet === 'dashboard') {
            this.outlet.deactivate();
          }
        }
      })
    );

    this.subs.add(
      this.store
      .select(selectForms)
      .subscribe(
        forms => {
          if(forms){ // forms will be null if the api call fails
            this.formList = forms.results.filter(form => !form.disabled);
          }
          else{
            this.formList = []
          }
        }
      ));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  @ViewChild(RouterOutlet, { static: false }) outlet: RouterOutlet;

  private subs: Subscription;

  configReady$: Observable<boolean> = this.actions$.pipe(
    ofType(EConfigActions.GetConfigSuccess),
    map(() => {
      return true;
    })
  );


  footer = {
    ...environment.footer,
    year: new Date().getFullYear()
  };

  sidebarItems: {
    link: string;
    icon: string;
    text: string;
    permission?: Permission;
    children?: {
      link: string;
      icon: string;
      text: string;
      permission?: Permission;
    }[];
  }[] = [
    { link: '/dashboard/map', icon: '', text: 'MENU_MAP_VIEW', permission: Permission.AccessEVIEWDashboard },
    {
      link: '/dashboard/posts/incident',
      icon: '',
      text: 'MENU_POSTS',
      permission: Permission.AccessIncidentRiskReports,
    },
    {
      link: '/dashboard/analysis',
      icon: '',
      text: 'MENU_ANALYSIS',
      permission: Permission.AccessAnalysisAndFiltersSection
    },
    {
      link: '/dashboard/analysis-templates',
      icon: '',
      text: 'MENU_ANALYSIS_FILTERS',
      permission: Permission.AccessAnalysisAndFiltersSection
    },
    {
      link: '/dashboard/forms-management',
      icon: '',
      text: 'FORMS_MANAGEMENT',
      permission: Permission.AccessAndModifyParametersSettings
    },
    {
      link: '/dashboard/tags',
      icon: '',
      text: 'MENU_TAGS',
      permission: Permission.AccessAndModifyParametersSettings
    },
    {
      link: '/dashboard/user-access-management',
      icon: '',
      text: 'ACCESS_MGMT',
      permission: Permission.AccessAndModifyParametersSettings
    },
    {
      link: '/dashboard/user-management',
      icon: '',
      text: 'USER_MANAGEMENT',
      permission: Permission.AccessAndModifyParametersSettings
    } 
  ];

  sidebarItemsSecondGroup: {
    link: string;
    icon: string;
    text: string;
    permission?: Permission;
    children?: {
      link: string;
      icon: string;
      text: string;
      permission?: Permission;
    }[];
  }[] = [
    ];

  menuVisibile: boolean = true;

  

  hasUserPermission(permission: Permission) {
    return AuthHelpers.User.HasUserPermission(this.store, permission);
  }

  onLogoutClick() {
    this.store.dispatch(new Logout());
  }

  onLanguageChange(languageCode) {
    if (languageCode && languageCode.length > 0) {
      this.currentLang = languageCode;
      this.translate.use(this.currentLang);
      localStorage.setItem('languageCode', languageCode);
    }
  }

  //function to open modal popup
  openCreateIncident(type) {
    let formData;
    if (this.formList && this.formList.length > 0) {
      formData = this.formList.filter(item => item.type === type);
    }
    const createIncidentModalRef = this.modalService.open(PostEditorPageComponent, {ariaLabelledBy: 'modal-basic-title', windowClass : "customModalWindowClass", size: "xl" });
    this.subs.add(
        createIncidentModalRef.componentInstance.modalCloseEvent.subscribe(($e) => {
        createIncidentModalRef.componentInstance.ngOnDestroy();
        createIncidentModalRef.dismiss();
      })
    );
    createIncidentModalRef.componentInstance.formId = (formData && formData[0]) ? formData[0]['id'] : 1;
    createIncidentModalRef.componentInstance.type = type;
  }

}
