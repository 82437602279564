import * as Leaflet from 'leaflet';
import { PostType } from '@eview/core/domain/post/post';
import { environment } from '@eview/core/environments/environment';
const defaults = environment.defaults;

export const customIcon = ({
  color,
  icon,
  type
}: {
  color: string;
  icon: string;
  type: string;
}): 

Leaflet.DivIcon => {
  
  const defaultColor = (type === PostType.Risk) ? defaults.tagRiskColor : defaults.tagIncidentColor;
  const ColorTag = (color != null && color != '') ? color : defaultColor;
  return Leaflet.divIcon({
    html: `<i class="{{icon ? ${icon} : ${defaults.tagIcon}}}" style="font-size: 48px; color: ${ColorTag}; text-shadow: 0 0 2px black; opacity: 1;"></i>`,
    className: '',
    iconAnchor: [14, 44]
  });
};

export const defaultIcon = (type) => customIcon({
  color: null,
  icon: defaults.tagIcon,
  type: defaults.tagType
});
export const userCursorIcon = customIcon({
  color: 'white',
  icon: defaults.tagIcon,
  type: ''
});
export const clickedIcon = userCursorIcon;